import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Good_list extends React.Component {
  state = {
    good_list: [],
    total_count:0,
    current_page:1,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    account_name:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();
  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.title,data.good_id,1);
}


  // 初始化数据
  init_data(title="",good_id="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.title=title;
    data.good_id=good_id
    data.p=page

    fn.ajax({
      url: '/good/list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          good_list: res.data.list,
          total_count:res.data.count

        })
      })
  }

  // 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.title,data.good_id,page);
  }
  // 设置当前good_id
  set_current_good_id(good_id){
    let that=this
    const {dispatch}=that.props
    dispatch(ACTION.set_good_id(good_id))
  }

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    let good_gate=true;
    if(that.props.userinfo.role_id==3&&that.props.userinfo.has_good==0){
      good_gate=false;
    }
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '名称',
      dataIndex: 'title',
      align: 'center',
    }, {
      title: '主图',
      dataIndex: 'main_pic',
      align: 'center',
      render: (text, record, index) => {
         

         return <img src={text} className="good-main-pic-view"/>
      }
    }, {
      title: '类型',
      dataIndex: 'part_id',
      align: 'center',
      render: (text, record, index) => {
         if(text==0){
           return <span>系统商品库</span>
         }else{
          return <span>我的商品库</span>
         }
      }
    }, 
    {
      title: '成本（元）',
      dataIndex: 'cost',
      align: 'center'
    },
    {
      title: '建议价（元）',
      dataIndex: 'price',
      align: 'center',
      render: (text, record, index) => {
         return <span >{text}</span>
      }
    },
    {
      title: '销量',
      dataIndex: 'sold',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        return <div>
          {good_gate?<NavLink to="/good/edit"><Button type="primary" onClick={that.set_current_good_id.bind(that,record.id)}>编辑</Button></NavLink>:''}
          
        </div>   
      }
    }];
    return (
      <div >
        

        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="商品编号">
            {
              getFieldDecorator('good_id', {

              })(
                <Input placeholder="商品编号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="商品名称">
            {
              getFieldDecorator('title', {

              })(
                <Input placeholder="商品名称" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          {good_gate?<NavLink to="/good/add"><Button type="primary"  >新增商品</Button></NavLink>:''}
          
        </div>
        <Table columns={columns}  dataSource={this.state.good_list} className="table-view"  pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }} />
      </div>

    );
  }
}

Good_list = Form.create()(Good_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Good_list)