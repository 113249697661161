import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Select, Table, Divider, Button, InputNumber,Switch, message, Popconfirm, Form, Input, Modal,Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import '../../../style/antd_table.less'
import* as action from '../../../redux/action'
import { set_admin_id } from '../../../redux/action'
const FormItem = Form.Item;
const { Option } = Select;
class Part_list extends React.Component {
  state = {
    data: [],
    role_data: [],
    role_id: 3,
    plan_type:undefined,
    plan_rate:undefined,

    admin_id: '',
    username: '',
  }
  // 搜索
  search = () => {
    
    let data = this.props.form.getFieldsValue();
    this.init_data(data.username);

  }
  // 初始化组件
  componentWillMount() {
    // this.init_role_data();
    this.init_data();
    
  }
  // 初始化角色数据
  init_role_data() {
    let that=this;
    fn.ajax({
      url: '/privilege/role_list.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          role_data: res.data,
        })
        // console.log(that.props)
        this.props.form.setFieldsValue({
          username:that.props.admin_username,
          role_id:that.props.admin_role_id
        });
        
      })
  }
  // 初始化数据
  init_data(username = '', role_id = 3) {
    fn.ajax({
      url: '/privilege/admin_list.php',
      data: {
        token: this.props.userinfo.token,
        role_id: role_id,
        page_size:1000,
        username: username
      }
    }).
      then((res) => {
        this.setState({
          data: res.data.list,
        })
      })
  }

  // 新增账号
  add_admin = () => {
    let that = this;
    let data = {};
    let url = '/privilege/admin_add.php';
    data.token = that.props.userinfo.token;
    data.username = that.state.username;
    data.role_id = that.state.role_id;
    data.admin_id = that.state.admin_id;
    data.realname=that.state.realname;

    // 校验参数
    if (!data.username) {
      message.error("昵称不能为空");
      return false;
    }
    if (data.admin_id) {
      url = '/privilege/admin_edit.php';
    }
    if(data.role_id==3){
      // 校验分成方案
      if(!that.state.plan_type){
        message.error("分成类型不能为空");
        return false;
      }
      // 校验分成比例
      if(!that.state.plan_rate){
        message.error("分成比例不能为空");
        return false;
      }
      data.plan_type=that.state.plan_type
      data.plan_rate=that.state.plan_rate
    }
    fn.ajax({
      url: url,
      data: data
    }).
      then((res) => {
        if (!data.admin_id) {
          message.success("新增成功!");
        } else {
          message.success("保存成功!");
        }
        // 隐藏对话框
        that.modal_hidden();
        that.init_data();
      })

  }


  
  

  // 查看账号详情
  detail(record){
     let that=this;
     const { dispatch } = this.props;
     dispatch(set_admin_id(record.id));
  }

  // 新增
  add() {
    let that = this;
    that.setState({
      modal_visible: true,
      modal_label: '新增合伙人'
    })
  }
  // 修改
  edit(data, index) {
    let that = this;
    let admin_id = data.id;
    if(!data.plan_type){
      data.plan_type=undefined
      data.plan_rate=undefined
    }
    // 获取当前账号值
    that.setState({
      admin_id: admin_id,
  
      modal_visible: true,
      modal_label: '修改合伙人',
      username: data.username,
      realname: data.realname,
      plan_type: data.plan_type,
      plan_rate: data.plan_rate,
    })
  }


  // 重置密码
  reset_pswd(record, index){
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = record.id;
    fn.ajax({
      url: '/privilege/reset_pswd.php',
      data: data
    }).
      then((res) => {
        message.success("重置密码成功!");
        // 隐藏对话框
        that.init_data();
      })
  }


  //隐藏弹出框
  modal_hidden = () => {
    let that = this;
    that.setState({
      modal_visible: false,
      modal_label: '',
      username: undefined,
      realname:undefined,
      admin_id: '',
      
      plan_type: undefined,
      plan_rate: undefined,
    })
  }
  // 监听账号
  change_username(e) {
    const { value } = e.target;
    let username=fn.filter_string(value)
    // 只能输入字母、数字
    this.setState({
      username: username
    })
    const { dispatch } = this.props;
    dispatch(action.set_admin_username(username));
  }
  // 监听真实名称
  change_realname(e){
    let that=this
    const { value } = e.target;
    that.setState({
      realname: value
    })
  }
  // 监听角色
  change_role = (value, option) => {
    let that = this;
    
    that.setState({
      role_id: value
    })
    
  }

  change_plan_rate=(value)=>{
    let that=this
    that.setState({
      plan_rate:value
    })
  }
  change_form_role= (value, option) => {
    let data = this.props.form.getFieldsValue();
    const { dispatch } = this.props;
    // dispatch(action.set_admin_username(data.username));
    // console.log(value)
    dispatch(action.set_admin_role_id(value));
    this.init_data(data.username, value);
    
  }
  //  更新用户状态
  change_admin_status =(record,checked) => {
    let that=this
    if(checked){
      that.update_admin_status(record,1)
    }else{
      that.update_admin_status(record,2)
    }
  }
  change_has_good=(record,checked) => {
    let that=this
    if(checked){
      that.update_has_good(record,1)
    }else{
      that.update_has_good(record,2)
    }
  }
  //  发送更新用户状态请求
  update_has_good(record,status){

    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = record.id;
    data.status=status;
    fn.ajax({
      url: '/privilege/update_has_good.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功!");
        // 隐藏对话框
        that.init_data();
      })
  }
  //  发送更新用户状态请求
  update_admin_status(record,status){

    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = record.id;
    data.status=status;
    fn.ajax({
      url: '/privilege/update_admin_status.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功!");
        // 隐藏对话框
        that.init_data();
      })
  }


   // 监听来源
 change_plan_type= (e, option) => {
  let that=this
  that.setState({
    plan_type:e.target.value
  })
}

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '姓名',
      dataIndex: 'realname',
      align: 'center',
      render:(text,record)=>{
        return (<span>{text}</span>)
      }

    }, {
      title: '手机号',
      dataIndex: 'username',
      align: 'center',
      render:(text,record)=>{
        return (<span>{text}</span>)
      }

    },{
      title: '角色',
      dataIndex: 'role_name',
      align: 'center'

    },{
      title: '分成方案',
      dataIndex: 'role_name',
      align: 'center',
      render:(rext,record)=>{
        if(record.plan_type==1){
          return <div><div>营业额分成比例: {record.plan_rate} %</div>
        </div>
        }else{
          return <div>
          <div>利润分成比例: {record.plan_rate} %</div>
          </div>
        }
        
      }
    },{
      title: '商品归属',
      dataIndex: 'has_good',
      align: 'center',
      render:(text,record)=>{
        let mygate=false;
        if(text==1){
         mygate=true;
        }
    return   <Switch checkedChildren="开启" unCheckedChildren="关闭" onChange={this.change_has_good.bind(that,record)}  checked={mygate} />
       }
    },{
      title: '账号状态',
      dataIndex: 'status',
      align: 'center',
      render:(text,record)=>{
        let mygate=false;
        if(text==1){
         mygate=true;
        }
        return   <Switch checkedChildren="正常" unCheckedChildren="禁用" onChange={this.change_admin_status.bind(that,record)}  checked={mygate} />
       }
    }, {
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <span>
  
          <Button type="primary" onClick={this.edit.bind(this, record, index)} >编辑</Button>
          <Divider type="vertical" />
          <Popconfirm title="确认重置密码为初始密码？" okText="是" cancelText="否" onConfirm={this.reset_pswd.bind(this, record)}>
            <Button type="primary"  >重置</Button>
          </Popconfirm>

     
        </span>
      ),
    }
    ];

    return (

      <div >
        <Form layout="inline" className="main-headerform-view">
          <FormItem label="手机号">
            {
              getFieldDecorator('username', {

              })(
                <Input placeholder="请输入手机号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>


          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          <Button type="primary" onClick={this.add.bind(this)} >新增合伙人</Button>
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />

        {/* 新增账号组件 begin*/}
        <Modal
          title={this.state.modal_label}
          visible={this.state.modal_visible}
          onOk={this.add_admin}
          onCancel={this.modal_hidden}
          // maskClosable={false}
          okText="提交"
          cancelText="取消"
        >
          <Input placeholder="请输入姓名" value={this.state.realname} onChange={this.change_realname.bind(that)} />
          <Input placeholder="请输入手机号" value={this.state.username} onChange={this.change_username.bind(that)}   style={{ marginTop: '15px', width: '100%' ,marginBottom:'0px' }}/>
          <Radio.Group  buttonStyle="solid" value={that.state.plan_type} onChange={that.change_plan_type} style={{ marginTop: '15px', width: '100%' }} >
            <Radio.Button value={1}>营业额分成</Radio.Button>
            <Radio.Button value={2}>利润分成</Radio.Button>
          </Radio.Group> 
          <InputNumber placeholder="请输入分成比例" min={1} max={100} value={this.state.plan_rate} onChange={this.change_plan_rate.bind(that)}  style={{ marginTop: '5px', width: '100%' }}/>
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 分成方案即时生效,请勿随意更改！</div>
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 手机号为登录账号！</div>
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 新账号初始密码为888888，可自行更改！</div>
        </Modal>
        {/* 新增账号组件 end*/}

      </div>

    );
  }
}

Part_list = Form.create()(Part_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,

    admin_role_id:state.admin_role_id
  }
};
export default connect(mapStateToProps)(Part_list)