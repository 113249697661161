import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio,Drawer } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import AutoComplete from 'react-bmapgl/Services/AutoComplete'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Device_plan extends React.Component {
  state = {
    data: [],
    good_data:[],
    type_data:[],
    device_type_id:undefined,
    current_num:0,
    total_count:0,
    device_window_visible:false,
    current_page:1,
    address:''
  }
  // 初始化组件
  componentWillMount() {
    let that=this
    that.init_type();
    that.init_data();
    // 获取商品数据
    that.init_good();

  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.s_name,data.type,1);
}
// 初始化型号
init_type(){
  let that=this
  let data = {};
  data.token = that.props.userinfo.token;
  data.page_size=1000;
  fn.ajax({
    url: '/device/type_list.php',
    data: data
  }).
    then((res) => {
      this.setState({
        type_data: res.data.list
      })
    })

}

  // 初始化数据
  init_data(name="",type='',page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.name=name;
    data.type=type;
    data.p=page
    fn.ajax({
      url: '/device/plan_list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          data: res.data.list,
          total_count:res.data.count

        })
      })
  }

  // 初始化商品数据
  init_good() {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    fn.ajax({
      url: '/good/all.php',
      data: data
    }).
      then((res) => {
        that.setState({
          good_data: res.data
        })
      })
  }
  change_type= (value, option) => {
    let that=this
    that.props.form.setFieldsValue({
      type:value
    })
    that.search()
  }
// 监听drawer显示
  show_device_window = () => {
    this.setState({
      device_window_visible: true,
    });
  };
// 监听drawer隐藏
  hidden_device_window = () => {
    let that=this
    that.setState({
      device_type_id:undefined,
      plan_data:[],
      device_window_visible: false,
    });
    that.props.form.setFieldsValue({
      device_type_id:undefined,
      name:undefined,
      plan_data:[]
    })
  };



// 获取提交地址
autocomplete_confirm(e){
  let value=e.item.value;
  let address=value.city+value.district+value.business;
  that.setState({
      address:address
  })
}

// 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.name,page);
  }
  // 设置场景id
  set_device_type_id(record){
    let that=this
    let plan_data=JSON.parse(record.good_data)
    that.setState({
      device_plan_id:record.id,
      plan_data:plan_data,
      device_window_visible:true
    })
   setTimeout(()=>{
    that.props.form.setFieldsValue({
      device_type_id:record.device_type_id,
      name:record.name,
      plan_data:plan_data
    })
   },200)
  }

  // 新增设备方案
  add_device_plan(){
    let that=this
    let formdata = that.props.form.getFieldsValue();
    that.props.form.validateFields((err, values) => {
      if (!err) {
        let plan_data=that.state.plan_data;
        // 校验商品方案
        for(let i=0;i<plan_data.length;i++){
          if(!plan_data[i].price||!plan_data[i].id||!plan_data[i].cost){
            message.error("商品方案不能为空！");
            return false;
          }
        }

        let data={}
        let url="/device/plan_add.php"
        data.token=this.props.userinfo.token;
        data.name=formdata.name;
        data.device_type_id=formdata.device_type_id;
        data.id=that.state.device_plan_id
        data.good_data=JSON.stringify(plan_data);

        if(that.state.device_plan_id){
          url="/device/plan_edit.php"
          data.id=that.state.device_plan_id
        }
      
        fn.ajax({
          url: url,
          data: data
        }).
          then((res) => {
            message.success(`操作成功`);
            that.hidden_device_window();
            that.init_data();
          })
      }
    });

  }
// 监听设备型号变化
  change_device_type= (value, option) => {
    let that=this;
    let type_data=that.state.type_data;
    that.props.form.setFieldsValue({
      device_type_id:value
    })

    for(let i=0;i<type_data.length;i++){
      if(type_data[i].id==value){
        let plan_data=[];
        let current_num=type_data[i].num;

        for(let j=0;j<current_num;j++){
          let tmp={}
          tmp.id=undefined;
          tmp.price=undefined;
          plan_data.push(tmp)
        }
        let tmp_data=that.props.form.getFieldsValue();
        that.props.form.resetFields();
        that.props.form.setFieldsValue({
           name:tmp_data.name
        })
        that.setState({
          plan_data:plan_data,
          current_num:current_num
        })
        return false;
      }
    }
}

// 监听方案的商品
change_plan_good=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  let good_data=that.state.good_data;
  
  // 自动填充成本与售价
  for(let i=0;i<good_data.length;i++){
    if(good_data[i].id==value){
      plan_data[index].id=value;
      plan_data[index].cost=good_data[i].cost;
      plan_data[index].price=good_data[i].price;
      that.setState({
        plan_data:plan_data
      })
      that.props.form.setFieldsValue({
        plan_data:plan_data
      })
      return false;
    }
  }

}

// 监听方案的价格
change_plan_price=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  plan_data[index].price=value;
  that.setState({
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_data:plan_data
  })

}
// 监听方案的成本
change_plan_cost=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  plan_data[index].cost=value;
  that.setState({
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_data:plan_data
  }) 
}

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '方案名称',
      dataIndex: 'name',
      align: 'center',
    }, {
      title: '型号',
      dataIndex: 'device_type',
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        return <div>
          <Button type="primary" onClick={that.set_device_type_id.bind(that,record)}>编辑</Button>
        </div>   
      }
    }
    ];
    // 初始化设备类型
    let type_view=null
    if(that.state.type_data){
      type_view=that.state.type_data.map((item)=>{
        return <Option value={item.id} key={item.id}>{item.name}</Option>
      })
    }
    // 初始化商品类型
    let good_view=null
    if(that.state.good_data){
      good_view=that.state.good_data.map((item)=>{
        let cost=fn.clear_zero(item.cost)
        let price=fn.clear_zero(item.price)
        return <Option value={item.id} key={item.id}>{item.title} -{cost} - {price}</Option>
      })
    }

    let plan_view=null;
    if(that.state.plan_data){
      plan_view=that.state.plan_data.map((item,index)=>{
        let cur=index+1;
        return <Form.Item
            label={" "+cur+" 号格子"} className="myformitem-row"

          >
            {getFieldDecorator('plan_data', {
              rules: [{
                required: true, message: '设备型号不能为空',
              }],
            })(<div></div>)}
            <div>
        
              <Select
                showSearch
                style={{ width: '300px' }}
                placeholder="请选择售卖商品"
                optionFilterProp="children"
                onChange={that.change_plan_good.bind(that,index)}
                value={item.id}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                  {good_view}
              </Select>
              <InputNumber placeholder="成本价格"  value={item.cost}  min={0.01} className="margin-left20" onChange={that.change_plan_cost.bind(that,index)} style={{width:"120px",marginLeft:"20px"}} /> 元
              <InputNumber placeholder="售卖价格"  value={item.price}  min={0.01} className="margin-left20" onChange={that.change_plan_price.bind(that,index)} style={{width:"120px",marginLeft:"20px"}} /> 元
            </div>

          </Form.Item>
      })
    }

    return (
      <div >
        
        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="方案名称">
            {
              getFieldDecorator('s_name', {

              })(
                <Input placeholder="请输入方案名称" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="设备型号">
            {
              getFieldDecorator('type', {

              })(
           
                <Select
                    showSearch
                    style={{ width: '300px' }}
                    placeholder="请选择设备型号"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>  
                    {type_view}
                  </Select>
              )
            }
          </FormItem>
          <FormItem>
         
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
    
             <Button type="primary" onClick={that.show_device_window} >新增方案</Button>
       
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view"   pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:10,
          total: this.state.total_count

        }} />

     <Drawer
          title="新增方案"
          placement="right"
          closable={true}
          maskClosable={true}
          width={900}
          onClose={this.hidden_device_window}
          visible={that.state.device_window_visible}
    
        >
          <div className="mydrawer-add-plan-window-view">

          
          <Form className="mydrawer-add-plan-form-view">
            
            <Form.Item
            label="方案名称" className="myformitem-row"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: '方案名称不能为空',
              }],
            })(
              <Input placeholder="请输入方案名称" maxLength={50} minLength={1} style={{width:"300px"}} />
            )}
          </Form.Item>

          <Form.Item
            label="设备型号" className="myformitem-row"
          >
            {getFieldDecorator('device_type_id', {
              rules: [{
                required: true, message: '设备型号不能为空',
              }],
            })(

              <Select
                    showSearch
                    style={{ width: '300px' }}
                    placeholder="请选择设备型号"
                    optionFilterProp="children"
                    onChange={that.change_device_type.bind(that)}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>  
                    {type_view}
                  </Select>

            )}
          </Form.Item>
          {plan_view}
          
            <Form.Item className="formitem-submit">
                    <Button type="primary"  onClick={that.add_device_plan.bind(that)}>提交</Button>
            </Form.Item>
        </Form>
        </div>
        </Drawer>

      </div>

    );
  }
}

Device_plan = Form.create()(Device_plan);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Device_plan)