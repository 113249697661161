/**
 * reducer
 */


import { type } from '../action';
import cookie from 'react-cookies'

let userinfo = cookie.load("userinfo");
if (!userinfo) {
    userinfo = null;
}
const initialState = {
    userinfo: userinfo,
    router_path: '/home',
    admin_id: '',
    admin_username:undefined,
    admin_role_id:0,
    good_id:undefined,
    scene_id:undefined
}

const ebikeData = (state = initialState, action) => {
    switch (action.type) {
        case type.LOGIN:

            return {
                ...state,
                userinfo: action.userinfo
            };
        case type.QUIT:
            return {
                ...state,
                userinfo: action.userinfo
            };
        case type.SET_USERINFO:
            return {
                ...state,
                userinfo: action.userinfo
            };
        case type.SET_ROUTER_PATH:
            return {
                ...state,
                router_path: action.router_path
            };
        case type.SET_ADMIN_ID:
            return {
                ...state,
                admin_id: action.admin_id
            };
        case type.SET_ADMIN_USERNAME:
            return {
                ...state,
                admin_username: action.admin_username
            };
            // 设置用户id
        case type.SET_ADMIN_ROLE_ID:
            return {
                ...state,
                admin_role_id: action.admin_role_id
            };
        // 设置商品id
        case type.SET_GOOD_ID:
            return {
                ...state,
                good_id: action.good_id
            };
        // 设置商品id
        case type.SET_SCENE_ID:
            return {
                ...state,
                scene_id: action.scene_id
            };
        default:

            return { ...state };
    }
};



export default ebikeData;