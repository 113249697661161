import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio,Drawer } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import AutoComplete from 'react-bmapgl/Services/AutoComplete'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Scene_list extends React.Component {
  state = {
    data: [],
    total_count:0,
    add_scene_window_visible:true,
    current_page:1,
    address:''

  }
  // 初始化组件
  componentWillMount() {
    this.init_data();

  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.name,1);
}


  // 初始化数据
  init_data(name="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.name=name;
    data.p=page
    fn.ajax({
      url: '/scene/list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          data: res.data.list,
          total_count:res.data.count

        })
      })
  }
  change_type= (value, option) => {
    let that=this
    that.props.form.setFieldsValue({
      type:value
    })
    that.search()
    
  }
// 监听drawer显示
  show_add_scene_window = () => {
    this.setState({
      add_scene_window_visible: true,
    });
  };
// 监听drawer隐藏
  hidden_add_scene_window = () => {
    this.setState({
      add_scene_window_visible: false,
    });
  };


 // 监听地址
 change_address=(e)=>{
  let that=this
  const {value}=e.target;
  console.log(value)
  that.setState({
      address:value
  })
}
// 获取提交地址
autocomplete_confirm(e){
  let value=e.item.value;
  let address=value.city+value.district+value.business;
  that.setState({
      address:address
  })
}

// 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.name,page);
  }
// 设置场景id
  set_scene_id(scene_id){
    let that=this
    const { dispatch } = this.props;
    dispatch(ACTION.set_scene_id(scene_id));

  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '场所名称',
      dataIndex: 'name',
      align: 'center',
    }, {
      title: '负责人',
      dataIndex: 'realname',
      align: 'center',
    }, {
      title: '联系方式',
      dataIndex: 'username',
      align: 'center',
    }, {
      title: '类型',
      dataIndex: 'type_name',
      align: 'center',
    }, {
      title: '地址',
      dataIndex: 'address',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        return <div>
          <NavLink to="/scene/edit"><Button type="primary" onClick={that.set_scene_id.bind(that,record.id)}>编辑</Button></NavLink>
        </div>   
      }
    }
    ];
    
    return (
      <div >
        
        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="场所名称">
            {
              getFieldDecorator('good_title', {

              })(
                <Input placeholder="请输入场所名称" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>

          <FormItem>
         
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          <NavLink to="/scene/add">
             <Button type="primary" onClick={that.show_add_scene_window} >新增场所</Button>
          </NavLink>
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view"   pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }} />
        {/* <Input  placeholder="请输入场所地址不能为空"   style={{width:"300px"}} id="scene-address"/>
           <AutoComplete input="scene-address" onConfirm={that.autocomplete_confirm.bind(that)}/>              */}
     {/* <Drawer
          title="新增场所"
          placement="right"
          closable={true}
          maskClosable={false}
          width={500}
          onClose={this.hidden_add_scene_window}
          visible={that.state.add_scene_window_visible}
    
        >
          <Form className="mydrawer-add-scene-window-view">
            <Form.Item
            label="名称" className="myformitem-row"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: '请输入1-50字的字符',
              }],
            })(
              <Input placeholder="地区+场所名称+场所标识" maxLength={50} minLength={1} style={{width:"300px"}} />
            )}
          </Form.Item>

          <Form.Item
            label="类型" className="myformitem-row"
          >
            {getFieldDecorator('type', {
              rules: [{
                required: true, message: '场所类型不能为空',
              }],
            })(
              <Select
              showSearch
              style={{ width: '300px' }}
              placeholder="请选择场所类型"
              optionFilterProp="children"
              // onChange={that.change_role}
              // value={that.state.role_id}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>

              <Option value="0">全部</Option>
            </Select>

            )}
          </Form.Item>
          <Form.Item
            label="地址"  className="myformitem-row"
          >
         <Input  placeholder="请输入场所地址不能为空"   style={{width:"300px"}} id="scene-address"/>
           <AutoComplete input="scene-address" onConfirm={that.autocomplete_confirm.bind(that)}/> 
          </Form.Item>
            <Form.Item className="formitem-submit">
                    <Button type="primary">提交</Button>
            </Form.Item>
        </Form>
        </Drawer> */}

      </div>

    );
  }
}

Scene_list = Form.create()(Scene_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Scene_list)