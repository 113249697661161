
import axios from 'axios'
import { Modal, message } from 'antd'
import { SERVER_PATH ,OSS_CLIENT,COS_CLIENT,OSS_UPLOAD_PATH, OSS_UPLOAD_ROOT } from '../config/config'
import cookie from 'react-cookies'
import emoji from 'emoji-regex';
import  { Quill } from 'react-quill';
export function ajax(options) {
   
    let loading;
    if (options.data && options.data.isShowLoading !== false) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = 'block';
    }
    return new Promise((resolve, reject) => {
        axios({
            url: options.url,
            method: 'post',
            baseURL: SERVER_PATH,
            timeout: 10000,
            data: (options.data ? options.data : {})
        }).then((response) => {
            if (options.data && options.data.isShowLoading !== false) {
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
            if (response.status == '200') {
                let res = response.data;
                if (res.errno == '0') {
                    resolve(res);
                } else if(res.errno == '1999'){
                  
                  cookie.remove("userinfo");
                  Modal.info({
                    title: "提示",
                    content: "登录状态已失效，请重新登录!"
                })
                window.location.reload()

                }else {
                    Modal.info({
                        title: "提示",
                        content: res.errmsg
                    })
                }
            } else {
                reject(response.data);
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
        }).catch((response) => {
            message.error("网络请求失败");
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'none';
        })
    });
}

// 显示loading
export function show_my_loading(){
    let loading;
    loading = document.getElementById('ajaxLoading');
    loading.style.display = 'block';
}

// 隐藏loading
export function hiddin_my_loading(){
    let loading = document.getElementById('ajaxLoading');
    loading.style.display = 'none';
}


export function getBase64fromurl(imgUrl) {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest();
    xhr.open("get", imgUrl, true);
    // 至关重要
    xhr.responseType = "blob";
    return new Promise((resolve, reject) => {
        xhr.onload = function () {
            if (this.status == 200) {
              //得到一个blob对象
              var blob = this.response;
              console.log("blob", blob)
              // 至关重要
              let oFileReader = new FileReader();
              oFileReader.onloadend = function (e) {
                // 此处拿到的已经是 base64的图片了
                let base64 = e.target.result;
                resolve(base64)
              };
              oFileReader.readAsDataURL(blob);
              //====为了在页面显示图片，可以删除====
              var img = document.createElement("img");
              img.onload = function (e) {
                window.URL.revokeObjectURL(img.src); // 清除释放
              };
              let src = window.URL.createObjectURL(blob);
              img.src = src
              //document.getElementById("container1").appendChild(img);
              //====为了在页面显示图片，可以删除====
        
            }
          }
          xhr.send();   
    })
    
  }
// 上传
export function upload(options) {

    axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    let loading;
    if (options.data && options.data.isShowLoading !== false) {
        loading = document.getElementById('ajaxLoading');
        loading.style.display = 'block';
    }
    return new Promise((resolve, reject) => {
        axios({
            url: options.url,
            method: 'post',
            baseURL: SERVER_PATH,
            timeout: 30000,
            data: (options.data ? options.data : {})
        }).then((response) => {
            if (options.data && options.data.isShowLoading !== false) {
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
            if (response.status == '200') {
                let res = response.data;
                if (res.errno == '0') {
                    resolve(res);
                } else if(res.errno == '1999'){
                  
                  cookie.remove("userinfo");
                  Modal.info({
                    title: "提示",
                    content: "登录状态已失效，请重新登录!"
                })
                window.location.reload()

                }else {
                    Modal.info({
                        title: "提示",
                        content: res.errmsg
                    })
                }
            } else {
                reject(response.data);
                loading = document.getElementById('ajaxLoading');
                loading.style.display = 'none';
            }
        }).catch((response) => {
            message.error("网络请求失败");
            loading = document.getElementById('ajaxLoading');
            loading.style.display = 'none';
        })
    });
}

// base64转码
export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

//获取参数
export function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return decodeURI(r[2]); return null;
    return null;
}

//生成唯一订单号
export function produce_order() {
    let mydate = new Date();
    return "jk" + mydate.getHours() + mydate.getMinutes() + mydate.getSeconds() + mydate.getMilliseconds() + Math.round(1000 + Math.random() * 8000);
}

// 获取文件后缀名
export function get_suffix(filename) {
    let index1 = filename.lastIndexOf(".");
    let index2 = filename.length;
    let suffix = filename.substring(index1 + 1, index2);
    return suffix;
}
// 获取上传文件名
export function get_new_filename(filename) {

    let suffix = get_suffix(filename);
    let new_filename = produce_order();
    let mydate = new Date();
    let month=mydate.getMonth() + 1;
    let day=mydate.getDate()

    if(month<10){
        month="0"+month
    }
    if(day<10){
        day="0"+day
    }
    return mydate.getFullYear()  + month  + day + "/" + new_filename + "." + suffix;
}
// 获取最新时间
export function getDay(day) {
    var today = new Date();
    var targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
    today.setTime(targetday_milliseconds); //注意，这行是关键代码

    var tDate = today.getDate();
    var tMonth = today.getMonth();

    tMonth = tMonth + 1;
    return tMonth + "/" + tDate;
}

// 判断是否为数字
export function isNumber(val) {
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if (regPos.test(val) || regNeg.test(val)) {
        return true;
    } else {
        return false;
    }
}

// 返回16位字母数字组合
export function filter_string(str,nun=16){
    return str.replace(/[^\w\.\/]/ig,'').substr(0,nun);
}

// 校验手机号
export function check_mobile(phone){
    if(!(/^1[3456789]\d{9}$/.test(phone))){ 
        return false; 
    } 
    return true;
}

// 获取url参数
export function get_url_params(url,par){
    var urlsearch = url.split('?');
   
    if(urlsearch.length<=1) return false;
    let pstr = urlsearch[1].split('&');
    for (var i = pstr.length - 1; i >= 0; i--) {
        var tep = pstr[i].split("=");
        if(tep[0] == par){
            return tep[1];
        }
    }
    return(false);
}	

// 获取url参数
export function get_url_params_from_xhs(url){
    var urlsearch = url.split('/');
    
   
    if(urlsearch.length<=1) return false;
    urlsearch = urlsearch[urlsearch.length-1].split('?');
    return urlsearch[0]
}	


export function get_time(){
    var date = new Date(); 
    var year = date.getFullYear();

    var month = date.getMonth()+1;
    var day = date.getDate();
    var hour = date.getHours();
    var minute = date.getMinutes();
    var second = date.getSeconds();

    if (hour < 10) {
        hour = "0" + hour;
    }
    if (month < 10) {
        month = "0" + month;
    }
    if (day < 10) {
        day = "0" + day;
    }
    if (minute < 10) {
        minute = "0" + minute;
    }
    if (second < 10) {
        second = "0" + second;
    }
    return year+'-'+month+'-'+day+' '+hour+':'+minute+':'+second
   
}

export function get_time_str(){
    return '[ '+get_time()+' ]'
}


// 获取汉字、英文字符总和
export function get_str_total_len(str){
    var len = 0;
    for (var i=0; i<str.length; i++) { 
     var c = str.charCodeAt(i); 
    //单字节加1 
     if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) { 
       len++; 
     } 
     else { 
      len+=2; 
     } 
    } 
    
    return parseInt(len/2);
}

// 校验非法字符与emoji
export function check_emoji(str){
    // if(str.find())
    if(str.indexOf("#") != -1||str.indexOf("@") != -1){
        return true;
    }
    let match = emoji().exec(str);
    if(match!=null) return true;
    return false;

}

// 获取返回数据
export function page_back(){
    window.history.go(-1);
}

// 老版本编辑器图片上传oss  
export function uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {

            let new_filename = OSS_UPLOAD_PATH + get_new_filename(file.name);

            COS_CLIENT.putObject({
                Bucket: 'deyu-1306633787', 
                Region: 'ap-beijing',  
                Key:  new_filename,
                StorageClass: 'STANDARD',
                Body: file
            }, function (err, data) {
                if(err){
                    console.log(err, data);  
                }else{
                    // 成功
                    resolve({
                        data: {
                            link: OSS_UPLOAD_ROOT + new_filename
                        }
                    })
                }
            });
        });

}

// 阿里云上传图片
export function upload_img(file){
    return new Promise((resolve, reject) => {
        if(file.type!="image/jpeg"&&file.type!="image/png"){
            message.error("图片仅支持jpg、png格式")
            reject()
        }
        if (file.size/1024/1024>2) {
            message.error("图片大小仅支持2MB以内！");
            reject()
        }
        let new_filename = get_new_filename(file.name);
        OSS_CLIENT.multipartUpload(OSS_UPLOAD_PATH + new_filename, file, {
            progress: function (percentage, checkpoint, res) {
            }
        }).then(function (result) {
            let image_url=OSS_UPLOAD_ROOT + OSS_UPLOAD_PATH + new_filename
            resolve(image_url);
        }).catch(function (err) {
            Modal.info({
                title: "提示",
                content: err.message
            })
        });

    })
      
}

// 腾讯云上传图片
export function tx_upload_img(file){
    return new Promise((resolve, reject) => {
        console.log(file)
        if(file.type!="image/jpeg"&&file.type!="image/png"){
            message.error("图片仅支持jpg、png格式")
            reject()
        }
        if (file.size/1024/1024>2) {
            message.error("图片大小仅支持2MB以内！");
            reject()
        }
        let new_filename = get_new_filename(file.name);
        COS_CLIENT.putObject({
            Bucket: 'deyu-1306633787', 
            Region: 'ap-beijing',  
            Key: OSS_UPLOAD_PATH + new_filename,
            StorageClass: 'STANDARD',
            Body: file
        }, function (err, data) {
            if(err){
                console.log(err, data);  
            }else{
                let image_url=OSS_UPLOAD_ROOT + OSS_UPLOAD_PATH + new_filename
                resolve(image_url);
            }
        });
    })
      
}

// react-quill 图片插入
export function reactquill_image(quill,file){
    // console.log(quill)
    tx_upload_img(file).then((url)=>{
        const cursorPosition =quill.getSelection().index;//获取当前光标位置
        quill.insertEmbed(cursorPosition, "image",url, Quill.sources.USER);//插入图片
        quill.setSelection(cursorPosition + 1);//光标位置加1
    })
}


// 封装数字去0
export function clear_zero(num){
    return parseFloat(Number(num).toFixed(2))
}