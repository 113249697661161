import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio,Drawer } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import AutoComplete from 'react-bmapgl/Services/AutoComplete'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'

const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Device_type extends React.Component {
  state = {
    data: [],
    device_type_id:undefined,
    total_count:0,
    device_window_visible:false,
    current_page:1,
    address:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();

  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.s_name,1);
}


  // 初始化数据
  init_data(name="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.name=name;
    data.p=page
    fn.ajax({
      url: '/device/type_list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          data: res.data.list,
          total_count:res.data.count

        })
      })
  }
  change_type= (value, option) => {
    let that=this
    that.props.form.setFieldsValue({
      type:value
    })
    that.search()
  }
// 监听drawer显示
  show_device_window = () => {
    this.setState({
      device_window_visible: true,
    });
  };
// 监听drawer隐藏
  hidden_device_window = () => {
    let that=this
    that.setState({
      device_type_id:undefined,
      device_window_visible: false,
    });
    that.props.form.setFieldsValue({
      name:undefined,
      num:undefined
    })
  };



// 获取提交地址
autocomplete_confirm(e){
  let value=e.item.value;
  let address=value.city+value.district+value.business;
  that.setState({
      address:address
  })
}

// 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.name,page);
  }
  // 设置场景id
  set_device_type_id(record){
    let that=this
    that.setState({
      device_type_id:record.id,
      device_window_visible:true
    })
    that.props.form.setFieldsValue({
      name:record.name,
      num:record.num
    })
  }

  // 新增设备型号
  add_device_type(){
    let that=this

    let formdata = that.props.form.getFieldsValue();
    that.props.form.validateFields((err, values) => {
      if (!err) {
        let data={}
        let url="/device/type_add.php"
 
        data.token=this.props.userinfo.token;
        data.name=formdata.name;
        data.num=formdata.num;
        if(that.state.device_type_id){
          url="/device/type_edit.php"
          data.id=that.state.device_type_id
        }
        fn.ajax({
          url: url,
          data: data
        }).
          then((res) => {
              message.success(`操作成功`);
              that.hidden_device_window();
              that.init_data();
          })
      }
    });

  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    }, {
      title: '型号',
      dataIndex: 'name',
      align: 'center',
    }, {
      title: '格子数量',
      dataIndex: 'num',
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        return <div>
          <Button type="primary" onClick={that.set_device_type_id.bind(that,record)}>编辑</Button>
        </div>   
      }
    }
    ];
    
    return (
      <div >
        
        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="型号">
            {
              getFieldDecorator('s_name', {

              })(
                <Input placeholder="请输入型号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>

          <FormItem>
         
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
    
             <Button type="primary" onClick={that.show_device_window} >新增型号</Button>
       
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view"   pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:10,
          total: this.state.total_count

        }} />

     <Drawer
          title="新增型号"
          placement="right"
          closable={true}
          maskClosable={true}
          width={500}
          onClose={this.hidden_device_window}
          visible={that.state.device_window_visible}
    
        >
          <Form className="mydrawer-add-scene-window-view">
            <Form.Item
            label="型号名称" className="myformitem-row"
          >
            {getFieldDecorator('name', {
              rules: [{
                required: true, message: '型号名称不能为空',
              }],
            })(
              <Input placeholder="请输入型号名称" maxLength={50} minLength={1} style={{width:"300px"}} />
            )}
          </Form.Item>

          <Form.Item
            label="格子数量" className="myformitem-row"
          >
            {getFieldDecorator('num', {
              rules: [{
                required: true, message: '格子数量不能为空',
              }],
            })(
              <Input placeholder="请输入格子数量" max={100} min={1} style={{width:"300px"}} />

            )}
          </Form.Item>
          
            <Form.Item className="formitem-submit">
                    <Button type="primary"  onClick={that.add_device_type.bind(that)}>提交</Button>
            </Form.Item>
        </Form>
        </Drawer>

      </div>

    );
  }
}

Device_type = Form.create()(Device_type);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Device_type)