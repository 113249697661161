/*
 * action 类型
 */

export const type = {
    LOGIN : 'LOGIN',
    QUIT : 'QUIT',
    SET_ROUTER_PATH:"SET_ROUTER_PATH",
    SET_USERINFO:"SET_USERINFO",
    SET_ADMIN_ID:"SET_ADMIN_ID",
    SET_ADMIN_USERNAME:"SET_ADMIN_USERNAME",
    SET_ADMIN_ROLE_ID:"SET_ADMIN_ROLE_ID",
    SET_GOOD_ID:'SET_GOOD_ID',
    SET_SCENE_ID:'SET_SCENE_ID'
    

}

// 登录
export function login(userinfo) {
    return {
        type:type.LOGIN,
        userinfo:userinfo
    }
}
//注销
export function quit() {
    return {
        type:type.LOGIN,
        userinfo:null
    }
}
// 设置路由路径
export function set_router_path(router_path){
    return {
        type:type.SET_ROUTER_PATH,
        router_path:router_path
    }
}

// 设置客户id
export function set_admin_id(admin_id){
    return {
        type:type.SET_ADMIN_ID,
        admin_id:admin_id
    }
}


// 记忆检索内容
export function set_admin_username(admin_username){
    return {
        type:type.SET_ADMIN_USERNAME,
        admin_username:admin_username
    }
}
// 记忆检索内容
export function set_admin_role_id(admin_role_id){
    return {
        type:type.SET_ADMIN_ROLE_ID,
        admin_role_id:admin_role_id
    }
}

// 设置商品id
export function set_good_id(good_id){
    return {
        type:type.SET_GOOD_ID,
        good_id:good_id
    }
}

// 设置场景id
export function set_scene_id(scene_id){
    return {
        type:type.SET_SCENE_ID,
        scene_id:scene_id
    }
}