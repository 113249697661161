import React from 'react'
import { connect } from "react-redux"
import { Menu, Icon, Button } from 'antd';
import { menuList,menurootList ,mymenurootList} from './../../config/menuConfig'
import { NavLink,Link } from 'react-router-dom'
import './index.less'
import { set_router_path } from '../../redux/action'
import '../../style/antd_menu.less'
const SubMenu = Menu.SubMenu;
const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1535706_t4r6ppd0d.js',
  });
class NavLeft extends React.Component {
    state = {
        currentKey: [],
        collapsed: false
    }

    componentWillMount() {
        let menuTreeNode = null;
        let that = this;


        menuTreeNode = this.renderMenu(menurootList,1);
       

        if (this.props.userinfo.company) {
            document.title = this.props.userinfo.company;
        }
        // 新增权限控制
        if(this.props.userinfo.role_name!="root"&&this.props.userinfo.menu_data){   
            menuTreeNode = this.renderMenu(this.props.userinfo.menu_data);
        }
        this.setState({
            menuTreeNode
        })
    }
    // 是否收起菜单
    toggleCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };

    clear_path=()=>{
        this.setState({
            currentKey:[]
        })
    }
    // // 菜单渲染
    renderMenu = (data,tag=0) => {
        return data.map((item) => {
            if (item.children) {
                if(item.icon){
                    return (
                        <SubMenu title={<span><IconFont type={item.icon} /><span>{item.title}</span></span>} key={item.key}>
                            {this.renderMenu(item.children)}
                        </SubMenu>
                    )

                }else{
                    return (
                        <SubMenu title={<span> <IconFont type="changjiangjackwenjian"/><span>{item.title}</span></span>} key={item.key}>
                            {this.renderMenu(item.children)}
                        </SubMenu>
                    )
                }
                
            }

            if(tag==1){
                return (<Menu.Item key={item.key}  >
                    <NavLink to={item.key} onClick={this.clear_path} >
                        {tag==1?<IconFont type={item.icon} /> :<IconFont type="changjiangjackwenjian"/>}
                        <span>{item.title}</span>
                    </NavLink>
                </Menu.Item>)

            }else{
                return (<Menu.Item key={item.key}  >
                    <NavLink to={item.key}>
                        <IconFont type="changjiangjackwenjian"/>
                        <span>{item.title}</span>
                    </NavLink>
                </Menu.Item>)
            }
    
        })
    }
    //    切换菜单yarn
    onOpenChange = (openKeys) => {

        let that = this;
        // console.log(openKeys)
        let latestOpenKey = openKeys.find(key => that.state.currentKey.indexOf(key) === -1);
        // console.log(latestOpenKey)
        that.setState({
            currentKey: [latestOpenKey]
        })
    }
    // 切换路由
    change_path = (item) => {
        // console.log("change")
        // console.log(item.key)
        const { dispatch } = this.props;
        dispatch(set_router_path(item.key))
    }
    render() {
        let that = this;
        let collapsed = that.state.collapsed;

        return (
            <div className={!collapsed ? "nav-left-content-view" : "nav-left-content-wrap-view"}>
                <div className="header-collapse-view">
                {!collapsed ? <NavLink to="/home" >
                    <div className="nav-left-logo-view">
                    {that.props.userinfo.company}
                    </div>
                </NavLink> : ''}
                    <Button type="primary" onClick={this.toggleCollapsed} className="nav-left-toggle-collapsed-view">
                        <Icon type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'} />
                    </Button>
                </div>
                

                <Menu
                    openKeys={this.state.currentKey}
                    // defaultOpenKeys={['/content','/income','/account','/privilege','/setting','/setting','/download']}
                    onOpenChange={this.onOpenChange}
                    mode="inline"
                    theme="dark"
                    onClick={this.change_path}
                    inlineCollapsed={this.state.collapsed}
                    className="menu-view"
         
                >
                    {this.state.menuTreeNode}
                </Menu>
            </div>
        );
    }

}

const mapStateToProps = state => {

    return {
        userinfo: state.userinfo,
        router_path: state.router_path
    }
};
export default connect(mapStateToProps)(NavLeft)