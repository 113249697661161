import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Shop_list extends React.Component {
  state = {
    data: [],
    count:undefined,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    username:undefined,
    company:undefined,
    mobile:undefined

  }
  // 初始化组件
  componentDidMount() {
    this.init_data();
  }

  // 搜索
  search = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.username);
  }

  // 初始化数据
  init_data(username="") {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.username=username;
    fn.ajax({
      url: '/shop/list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          data: res.data.list,
          count:res.data.count
        })
      })
  }

  change_type= (value, option) => {
    let that=this
    that.props.form.setFieldsValue({
      type:value
    })
    that.search()
    
  }
  shop_modal_show=()=>{
    let that=this
    that.setState({
      modal_visible:true
    })
  }
  shop_modal_hidden=()=>{
    let that=this
    that.setState({
      modal_visible:false,
      username:undefined,
      mobile:undefined,
      company:undefined
    })
  }
// 新增商户
  add_shop=()=>{
    let that=this;
    let username=that.state.username;
    let company=that.state.company;
    let mobile=that.state.mobile;
    if(username==undefined||username==''){
      message.error("商户账号不能为空！");
      return false;
    }
    if(company==undefined||company==""){
      message.error("品牌名称不能为空");
      return false
    }
    if(mobile==undefined||mobile==''){
      message.error("联系号码不能为空");
      return false
    }
    let data = {};
    data.token = that.props.userinfo.token;
    data.username=username;
    data.company=company;
    data.mobile=mobile;
    fn.ajax({
      url: '/shop/add.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！");
        that.shop_modal_hidden();
        that.init_data();
      })
  }
  // 监听角色
  change_username(e) {
    const { value } = e.target;
    this.setState({
      username: value
    })
  }

  change_company(e){
    const { value } = e.target;
    this.setState({
      company: value
    })
  }

  change_mobile(e){
    const { value } = e.target;
    this.setState({
      mobile: value
    })
  }

  // 选择级别
  change_rank(e){
    // const { value } = e.target;
    console.log(e)
    this.setState({
      rank: e
    })
  
  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '商户账号',
      dataIndex: 'username',
      align: 'center',
    }, {
      title: '公司',
      dataIndex: 'company',
      align: 'center',
    }, 
    {
      title: '手机号',
      dataIndex: 'mobile',
      align: 'center',
    }, 
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }
    ];
    
    return (
      <div >
        <Form layout="inline" className="main-headerform-view">
          <FormItem label="商户账号">
            {
              getFieldDecorator('username', {
              })(
                <Input placeholder="请输入商户账号关键词" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          <Button type="primary" onClick={this.shop_modal_show.bind(this)}>新增商户</Button>
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />

        <Modal
          title="新增商户"
          visible={this.state.modal_visible}
          onOk={this.add_shop}
          onCancel={this.shop_modal_hidden}
          okText="提交"
          cancelText="取消"
        >
          <Input placeholder="请输入商户账号" value={this.state.username} onChange={this.change_username.bind(that)}  />
          <Input placeholder="请输入品牌名称" value={this.state.company} onChange={this.change_company.bind(that)}  style={{ marginTop: '15px', width: '100%' }} />
          <Input placeholder="请输入联系手机号" value={this.state.mobile} onChange={this.change_mobile.bind(that)}  style={{ marginTop: '15px', width: '100%' }} />
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 新账号初始密码为888888，可自行更改！</div>
       </Modal>
      </div>

    );
  }
}

Shop_list = Form.create()(Shop_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Shop_list)