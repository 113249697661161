import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as fn from '../../../utils/index'
import * as CONFIG from '../../../config/config';
import {
  Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
} from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

class Good_edit extends React.Component {
  state = {
    good_data:{},
    pic_info:[],
    desc:''
  };
  componentDidMount() {
    let that=this
    if(that.props.good_id){
      that.init_data(that.props.good_id) 
    }

  }

  // 初始化数据
  init_data(good_id){
    let that=this
    let data = {};
    data.token = that.props.userinfo.token;
    data.good_id=good_id
  
    fn.ajax({
      url: '/good/get.php',
      data: data
    }).
      then((res) => {
        let good_data=res.data;


        that.props.form.setFieldsValue({
          title:good_data.title,
          tag:good_data.tag,
          price:good_data.price,
          cost:good_data.cost,
          desc:good_data.desc,
          pic_list:JSON.parse(good_data.pic_list)
        })
        that.setState({
          desc:good_data.desc,
          good_data: good_data,
          pic_info:JSON.parse(good_data.pic_list)
        })
      })
  }
  
  // 返回上一页
  goback(){
    let that=this
    fn.page_back();
  }
  // 监听编译器
  change_quil_text(value) {
    let that=this
    that.setState({ desc: value })
    that.props.form.setFieldsValue({
      desc:value
    })
  }

  // 添加商品
  edit_good(){
    let that=this
    let pic_info=that.state.pic_info;
    if(pic_info.length==0){
      message.error("至少选择一张图片");
      return false;
    }
    let formdata = that.props.form.getFieldsValue();
    that.props.form.validateFields((err, values) => {
      if (!err) {
        let data={}
     
        data.token=this.props.userinfo.token;
        data.pic_list=JSON.stringify(pic_info);
        data.title=formdata.title;
        data.desc=formdata.desc;
        data.tag=formdata.tag;
        data.price=formdata.price;
        data.cost=formdata.cost;
        data.good_id=that.props.good_id;
        fn.ajax({
          url: '/good/edit.php',
          data: data
        }).
          then((res) => {
              message.success(`操作成功`);
              fn.page_back();
          })
      }
    });
  }

    // 上移
    pic_up(index){
      let that=this
      let pic_info=that.state.pic_info
      let tmp=that.state.pic_info[index-1];
      that.state.pic_info[index-1]=that.state.pic_info[index]
      that.state.pic_info[index]=tmp;
      that.setState({
        pic_info:pic_info
      })
    
    }
  // 下移
    pic_down(index){
      let that=this
      let pic_info=that.state.pic_info
      let tmp=that.state.pic_info[index+1];
      that.state.pic_info[index+1]=that.state.pic_info[index]
      that.state.pic_info[index]=tmp;
      that.setState({
        pic_info:pic_info
      })
    }
    // 移除图片
  remove_image(pic_cur){
  
    let that=this
    let pic_info=that.state.pic_info
    pic_info.splice(pic_cur, 1);
    that.setState({
      pic_info:pic_info,
      pic_cur:-1
    })
  }
  // 上传图片
  beforeUploadimage  (index,file)  {
    let that=this
    let pic_info=that.state.pic_info;
   
    fn.tx_upload_img(file).then((url)=>{
      if(pic_info.length==index){
        pic_info.push(url)
      }else{
        pic_info[index]=url;
      }
      that.setState({
        pic_info:pic_info
      })
    })
    return false;
  }
   // 富文本内图片上传
   reactquill_upload(e){
    let that=this
    const file = e.target.files[0];
    fn.reactquill_image(that.state.quill,file)
    return false;
  }
  // 显示上传框
  reactquill_upload_show(toolbar){
    // console.log(t)
    let that=this
    that.refs.uploadInput.click();
    that.setState({
      quill:toolbar.quill
    })
  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    CONFIG.REACTQUILL_MODULES.toolbar.handlers['image']=function(){
      that.reactquill_upload_show(this)
    }
    let pic_list_view=null
    if(that.state.pic_info.length>0){
      pic_list_view=that.state.pic_info.map((item,index)=>{
       return <div className="myvideo-wrap-view" key={'pic'+index}>
       <Upload
       name="image"
       listType="picture-card"
        style={{width:"auto"}}
       showUploadList={false}
       beforeUpload={this.beforeUploadimage.bind(this,index)}
       >
         {item.url==""?<div className="custom-upload-view-notes" >
         <Icon type= 'plus' className="plus-image-style"/>
         <div className="ant-upload-text">选择文件</div>
       </div>:<div className="custom-upload-view-notes" ><img src={item} className="myupload-img-style-notes"/></div>}
     </Upload>

     <div className="mynotes-wrap-column-view">
       <div className="notes-wrap-row-view">
         <Button type="primary"   onClick={that.remove_image.bind(that,index)}  className="margin-left20 margin-buttom10">移除</Button>
         {index>0&&that.state.pic_info.length>=2?<Button type="primary"   onClick={that.pic_up.bind(that,index)}  className="margin-left20 margin-buttom10">上移</Button>:''}
         {that.state.pic_info.length>=2&&that.state.pic_info.length-1>index?<Button type="primary"   onClick={that.pic_down.bind(that,index)}  className="margin-left20 margin-buttom10">下移</Button>:''}
       </div>
     </div>
     </div>
      })
    }

    
    return (
      <Card  className="window-view " title={<div className="window-header-view" >
        <Button type="primary" className="window-close-view" onClick={that.goback.bind(this)}><Icon type='arrow-left' />返回</Button>
        <div className="window-title-view">商品编辑</div>
      </div>}>
        <div className="addform-view">
         <Form className="normal-form-view">
        
         <Form.Item label="商品图片" extra="PS:第一张为商品主图， 比例建议：1:1" >
         {getFieldDecorator('pic_list', {
            rules: [{
              required: true, message: '至少上传一张图片',
            }],
          })(
            <div>{pic_list_view}
                 <div className="myvideo-wrap-view" >
                <Upload
                name="image"
                listType="picture-card"
                  style={{width:"auto"}}
                showUploadList={false}
                beforeUpload={this.beforeUploadimage.bind(this,that.state.pic_info.length)}
                >
                <div className="custom-upload-view-notes" >
                  <Icon type= 'plus' className="plus-image-style"/>
                  <div className="ant-upload-text">选择文件</div>
                </div>
                </Upload>
                </div>
            </div>  
          )}
             
        </Form.Item>
   
        <Form.Item
          label="标题"
        >
          {getFieldDecorator('title', {
            rules: [{
              required: true, message: '商品标题不能为空',
            }],
          })(
            <Input placeholder="请输入1-19字的标题" maxLength={19} minLength={1}  />
          )}
        </Form.Item>
        <Form.Item
          label="标签"
        >
          {getFieldDecorator('tag', {
       
          })(
            <Input placeholder="标签为10字以内，突出商品特点，吸引用户购买" maxLength={10} minLength={1}  />
          )}
        </Form.Item>
        <Form.Item
          label="价格"
        >
          {getFieldDecorator('price', {
            rules: [{
              required: true, message: '价格不能为空',
            }],
          })(
            <InputNumber min={0.01}  placeholder="请输入商品价格" style={{width:"300px"}} />
          )}
        </Form.Item>
        <Form.Item
          label="成本"
        >
          {getFieldDecorator('cost', {
            rules: [{
              required: true, message: '成本不能为空',
            }],
          })(
            <InputNumber min={0.01}  placeholder="请输入商品成本"  minLength={1} style={{width:"300px"}} />
          )}
        </Form.Item>

       
        <Form.Item
            label="商品详情" 
          >
            {getFieldDecorator('desc', {
              rules: [{
                required: true, message: '商品详情不能为空',
              }],
            })(
              <div></div>
            )}
            <div className="reactquill-mobile-view">
            <ReactQuill  modules={CONFIG.REACTQUILL_MODULES}  value={that.state.desc}
              onChange={that.change_quil_text.bind(that)} className="myreact-quil-view" />
              <input ref="uploadInput" type='file' accept='image/*' hidden={true} onChange={this.reactquill_upload.bind(this)}/>
            </div>
          </Form.Item>
          <Form.Item className="layout-center">
                  <Button type="primary" onClick={this.edit_good.bind(this)}>保存</Button>
                </Form.Item>
        </Form>
        </div>
      </Card>
    );
  }
}

Good_edit = Form.create()(Good_edit);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    good_id:  state.good_id
  }
};
export default connect(mapStateToProps)(Good_edit)












