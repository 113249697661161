import OSS from "ali-oss"
import COS from 'cos-js-sdk-v5'
import * as fn from '../utils/index'


const OSS_UPLOAD_PATH="public/";
const OSS_UPLOAD_ROOT="https://pic.dexinyu.cn/";
//阿里云
let OSS_CLIENT = new OSS({
    region: 'oss-cn-hangzhou',
    accessKeyId: 'LTAI4FtHdPWX4KMzqgep3nze',
    accessKeySecret: 'gAuNOGzWzzU5PtpH3W8k6SKPA3Du1h',
    bucket: 'begin-educate'
});
// 腾讯云 公司
let COS_CLIENT = new COS({
    SecretId: 'AKIDAE8rOiqnX3LPHwNaYLuJTQ8pY8bv1sTo',
    SecretKey: '9bsxzqWzGJ9l9MM8i7KLAmCQUM3WkkW3'
});
// // 腾讯云 私人
// let COS_CLIENT = new COS({
//     SecretId: 'AKIDc2C5hY9TWJp31qOffOr3xax3rOffNoxq',
//     SecretKey: 'e17xWnnX0C6j5B1atvf8LGuHNmytXu0p'
// });
// SecretId: AKIDc2C5hY9TWJp31qOffOr3xax3rOffNoxq 
// SecretKey: e17xWnnX0C6j5B1atvf8LGuHNmytXu0p

const  DOMAIN="api.dexinyu.cn";
const  ROOT_PATH="https://"+DOMAIN+"/";
const  SERVER_PATH=ROOT_PATH+"admin/";
const  SOCKET_URL='ws://'+DOMAIN+':8282';

// 编译器参数
const REACTQUILL_MODULES= {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image', 'video']
      ],
      handlers: {

      },
      clipboard: {
        matchVisual: false,
      }
    },
  }

const REACTQUILL_FORMATS=  [
    'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ]



 export {OSS_UPLOAD_PATH,OSS_UPLOAD_ROOT,ROOT_PATH,SERVER_PATH,OSS_CLIENT,COS_CLIENT,SOCKET_URL,REACTQUILL_MODULES,REACTQUILL_FORMATS};





