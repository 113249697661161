import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio,Drawer } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Device_list extends React.Component {
  state = {
    data: [],
    type_data:[],
    good_data:[],
    scene_data:[],
    total_count:0,
    admin_id:undefined,
    device_id:undefined,
    admin_data:[],
    modal_visible:false,
    device_window_visible:false,
    current_page:'1'
  }
  // 初始化组件
  componentWillMount() {
    let that=this;
    that.init_type();
    that.init_data();
    that.init_scene();
    // 获取商品数据
    that.init_good();
  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.name,data.type,1);
}

 // 初始化场景数据
 init_scene() {
  let that = this;
  let data = {};
  data.token = that.props.userinfo.token;
  fn.ajax({
    url: '/scene/all.php',
    data: data
  }).
    then((res) => {
      that.setState({
        scene_data: res.data
      })
    })
}
 // 初始化商品数据
 init_good() {
  let that = this;
  let data = {};
  data.token = that.props.userinfo.token;
  fn.ajax({
    url: '/good/all.php',
    data: data
  }).
    then((res) => {
      that.setState({
        good_data: res.data
      })
    })
}
// 初始化型号
  init_type(){
    let that=this
    let data = {};
    data.token = that.props.userinfo.token;
    data.page_size=1000;
    fn.ajax({
      url: '/device/type_list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          type_data: res.data.list
        })
      })

  }
  // 初始化数据
  init_data(name="",type,page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.name=name;
    data.p=page;
    data.type=type;

    fn.ajax({
      url: '/device/list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          data: res.data.list,
          total_count:res.data.count
        })
      })
  }

  // 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.title,page);
  }

// 监听drawer显示
show_device_window = () => {
  this.setState({
    device_window_visible: true,
  });
};
// 监听drawer隐藏
hidden_device_window = () => {
  let that=this
  that.setState({
    device_id:undefined,
    plan_data:[],
    device_window_visible: false,
  });
  that.props.form.setFieldsValue({
    name:undefined,
    type:undefined,
    soft_version:undefined,
    scene_id:undefined,
    room:undefined,

    plan_data:[]
  })
};
// 监听方案的商品
change_plan_good=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  let good_data=that.state.good_data;
  
  // 自动填充成本与售价
  for(let i=0;i<good_data.length;i++){
    if(good_data[i].id==value){
      plan_data[index].id=value;
      plan_data[index].cost=good_data[i].cost;
      plan_data[index].price=good_data[i].price;
      that.setState({
        plan_data:plan_data
      })
      that.props.form.setFieldsValue({
        plan_data:plan_data
      })
      return false;
    }
  }

}

// 监听方案的价格
change_plan_price=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  plan_data[index].price=value;
  that.setState({
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_data:plan_data
  })

}

// 监听方案的成本
change_plan_cost=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;
  plan_data[index].cost=value;
  that.setState({
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_data:plan_data
  }) 
}
// 设置当前设备
set_current_device(record){
  let that=this
  let plan_data=JSON.parse(record.good_data)
    that.setState({
      device_id:record.id,
      plan_data:plan_data,
      device_window_visible:true
    })
   setTimeout(()=>{
    that.props.form.setFieldsValue({
      name:record.name,
      type:record.type,
      soft_version:record.soft_version,
      scene_id:record.scene_id,
      room:record.room,
      plan_data:plan_data
    })
   },200)
}
// 编辑设备信息
edit_device(){
  let that=this
  let formdata = that.props.form.getFieldsValue();
    that.props.form.validateFields((err, values) => {
      if (!err) {
        let plan_data=that.state.plan_data;
        // 校验商品方案
        for(let i=0;i<plan_data.length;i++){
          if(!plan_data[i].price||!plan_data[i].id){
            message.error("商品方案不能为空！");
            return false;
          }
        }

        let data={}
        let url="/device/edit.php"
        data.token=this.props.userinfo.token;
        data.scene_id=formdata.scene_id;
        data.room=formdata.room;
        data.id=that.state.device_id
        data.good_data=JSON.stringify(plan_data);

      
        fn.ajax({
          url: url,
          data: data
        }).
          then((res) => {
            message.success(`操作成功`);
            that.hidden_device_window();
            that.init_data();
          })
      }
    });
}

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '设备号',
      dataIndex: 'name',
      align: 'center',
    },{
      title: '设备型号',
      dataIndex: 'type',
      align: 'center',
    }, 
    // {
    //   title: '固件版本',
    //   dataIndex: 'soft_version',
    //   align: 'center',
    // },
    {
      title: '场所名称',
      dataIndex: 'scene_name',
      align: 'center',
      render:(text)=>{
        if(text){
          return <span >{text}</span>
        }else {
          return <span >暂无</span>
        }
      }
    },{
      title: '房间号',
      dataIndex: 'room',
      align: 'center',
      render:(text)=>{
        if(text){
          return <span >{text}</span>
        }else {
          return <span >暂无</span>
        }
      }
    }, {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      render:(text)=>{
        if(text==0){
          return <span >未绑定</span>
        }else if(text==1){
          return <span className="primary-font1">已绑定</span>
        }
      }
    }, 
    {
      title: '激活时间',
      dataIndex: 'create_time',
      align: 'center'
    },{
      title: '操作',
      align: 'center',
      render:(text,record)=>{
        if(record.status>=1){
          return <div>
          <Button type="primary" onClick={this.set_current_device.bind(that,record)} className="submit-search-style">编辑</Button>
        </div>
        }else{
          return <div>暂无</div>
        }
      }
    }
    ];
    // 初始化
    let type_view=null
    if(that.state.type_data){
      type_view=that.state.type_data.map((item)=>{
        return <Option value={item.id} key={item.id}>{item.name}</Option>
      })
    }
// 初始化商品类型
let good_view=null
if(that.state.good_data){
  good_view=that.state.good_data.map((item)=>{
    let cost=fn.clear_zero(item.cost)
    let price=fn.clear_zero(item.price)
    return <Option value={item.id} key={item.id}>{item.title} -{cost} - {price}</Option>
  })
}
// 初始化场景数据
let scene_view=null
if(that.state.scene_data){
  scene_view=that.state.scene_data.map((item)=>{
    return <Option value={item.id} key={item.id}>{item.name}</Option>
  })
}

    let plan_view=null;
    if(that.state.plan_data){
      plan_view=that.state.plan_data.map((item,index)=>{
        let cur=index+1;
        return <Form.Item
        label={" "+cur+" 号格子"} className="myformitem-row"

      >
        {getFieldDecorator('plan_data', {
          rules: [{
            required: true, message: '设备型号不能为空',
          }],
        })(<div></div>)}
        <div>
    
          <Select
            showSearch
            style={{ width: '300px' }}
            placeholder="请选择售卖商品"
            optionFilterProp="children"
            onChange={that.change_plan_good.bind(that,index)}
            value={item.id}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
              {good_view}
          </Select>
          <InputNumber placeholder="成本价格"  value={item.cost}  min={0.01} className="margin-left20" onChange={that.change_plan_cost.bind(that,index)} style={{width:"120px",marginLeft:"20px"}} /> 元
          <InputNumber placeholder="售卖价格"  value={item.price}  min={0.01} className="margin-left20" onChange={that.change_plan_price.bind(that,index)} style={{width:"120px",marginLeft:"20px"}} /> 元
        </div>

      </Form.Item>
      })
    }
    return (
      <div >
        <Form layout="inline" className="main-headerform-view">
        <FormItem label="设备号">
            {
              getFieldDecorator('name', {

              })(
                <Input placeholder="请输入设备号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>

        
          <FormItem label="设备型号">
            {
              getFieldDecorator('type', {

              })(
           
                <Select
                    showSearch
                    style={{ width: '300px' }}
                    placeholder="请选择设备型号"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>  
                    {type_view}
                  </Select>
              )
            }
          </FormItem>
          
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          {/* <Button type="primary"  >新增设备</Button> */}
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }}  />


<Drawer
          title="新增方案"
          placement="right"
          closable={true}
          maskClosable={true}
          width={900}
          onClose={this.hidden_device_window}
          visible={that.state.device_window_visible}
        >
        <div className="mydrawer-add-plan-window-view">
          <Form className="mydrawer-add-plan-form-view">
            <Form.Item
            label="设备编号" className="myformitem-row"
          >{getFieldDecorator('name', { 
            rules: [{
              required: true, message: '设备编号不能为空',
            }],
          })(
            <Input placeholder="请输入设备编号" maxLength={50} minLength={1} style={{width:"300px"}} disabled={true}/>
          )}
          </Form.Item>

            <Form.Item
            label="设备型号" className="myformitem-row"
          >
            {getFieldDecorator('type', { 
              rules: [{
                required: true, message: '设备型号不能为空',
              }],
          })(
            <Input placeholder="请输入设备编号" maxLength={50} minLength={1} style={{width:"300px"}} disabled={true} />
            )}
          </Form.Item>
          <Form.Item
            label="固件版本" className="myformitem-row"
          >
            {getFieldDecorator('soft_version', { 
              rules: [{
                required: true, message: '固件版本不能为空',
              }],
          })(
            <Input placeholder="请输入固件版本" maxLength={50} minLength={1} style={{width:"300px"}} disabled={true} />
            )}
          </Form.Item>

          <Form.Item
            label="场所名称" className="myformitem-row"
          >
            {getFieldDecorator('scene_id', { 
              rules: [{
                required: true, message: '场所不能为空',
              }],
          })(
            <Select
                    showSearch
                    style={{ width: '300px' }}
                    placeholder="请选择场所"
                    optionFilterProp="children"
                    // onChange={that.change_device_type.bind(that)}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>  
                    {scene_view}
                  </Select>
            )}
          </Form.Item>
          <Form.Item
            label="房间编号" className="myformitem-row"
          >
            {getFieldDecorator('room', { 
              rules: [{
                required: true, message: '房间编号不能为空',
              }],
          })(
            <Input placeholder="请输入房间编号" maxLength={50} minLength={1} style={{width:"300px"}}  />
            )}
          </Form.Item>
          {plan_view}
            <Form.Item className="formitem-submit">
                    <Button type="primary"  onClick={that.edit_device.bind(that)}>提交</Button>
            </Form.Item>
        </Form>
        </div>
        </Drawer>
      </div>

    );
  }
}

Device_list = Form.create()(Device_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Device_list)