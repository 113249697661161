import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class User_list extends React.Component {
  state = {
    good_list: [],
    total_count:0,
    current_page:1,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    account_name:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();
  }
// 搜索
search = () => {

  let data = this.props.form.getFieldsValue();
  this.init_data(data.user_id);
}


  // 初始化数据
  init_data(user_id="") {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.user_id=user_id;


    fn.ajax({
      url: '/user/list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          good_list: res.data.list,
          total_count:res.data.count

        })
      })
  }

  // 监听页面切换
  change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.title,data.good_id,page);
  }

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '昵称',
      dataIndex: 'nickname',
      align: 'center',
    }, {
      title: '头像',
      dataIndex: 'headimage',
      align: 'center',
      render: (text, record, index) => {
         
 
         return <img src={text} className="user-logo-view"/>
      }
    }, {
      title: '用户标识',
      dataIndex: 'openid',
      align: 'center',
      render: (text, record, index) => {
         return <span >{text}</span>
      }
    },
 
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
          
      }
    }
    ];
    
    return (
      <div >
        

        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="用户编号">
            {
              getFieldDecorator('user_id', {

              })(
                <Input placeholder="请输入用户编号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>

          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        {/* <div className="main-add-view">
          <NavLink to="/good/add"><Button type="primary"  >新增用户</Button></NavLink>
        </div> */}
        <Table columns={columns}  dataSource={this.state.good_list} className="table-view" style={{marginTop:"20px"}}  pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }} />
      </div>

    );
  }
}

User_list = Form.create()(User_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(User_list)