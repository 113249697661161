import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'

import { Table,Transfer, Divider, Button, Card, message, Popconfirm, Icon,Form, Input,InputNumber, Pagination,Select, Row, Col ,Modal} from 'antd';
import { Redirect ,NavLink} from 'react-router-dom'
import { connect } from "react-redux"

import  { set_router_path }  from  './../../../redux/action'
const FormItem = Form.Item;
const { Option } = Select;
class Admin_detail extends React.Component {
  state = {
    data: [],
    daren_list:[],
    task_data:[],
    login_data:[],
    daren_key_data:[],
    parts_key_data:[],
    parts_list:[],
  
    task_window_visible:false,
    base_info_window_visible:false,
    base_info_type:'',
    account_name:undefined,
    normal_num:'',
    task_id:'',
    weitao_num:'',
    alipay:'',
    nickname:'',
    mobile:'',
    identify_card:''

  }

  componentWillMount() {
    let that=this;
    if(this.props.admin_id){
      that.init_data();
    }
    
    
  }
  // 初始化数据
  init_data(){
    let that=this;

    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;
    fn.ajax({
      url: '/privilege/admin_detail.php',
      data: data
    }).
      then((res) => {

        that.setState({
          data: res.data.base_info,
          task_data:res.data.task_data,
          daren_list:res.data.daren_list,
          parts_key_data:res.data.parts_key_data,
          parts_list:res.data.parts_list,
          daren_key_data:res.data.daren_key_data,
          login_data:res.data.login_data
        });
      })
  }
  // 更新绑定的达人
  update_daren(targetKeys){
    let that=this;
    let daren_str=targetKeys.join(",")
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;
    data.daren_str=daren_str;
    fn.ajax({
      url: '/privilege/admin_daren_update.php',
      data: data
    }).
      then((res) => {
        
        message.info("操作成功！")
        this.setState({ daren_key_data:targetKeys });
        that.init_data();
      })
  }

  update_parts(targetKeys){
    let that=this;

    let data = {};
    let parts_str=targetKeys.join(",")
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;
    data.parts_str=parts_str;
    fn.ajax({
      url: '/privilege/admin_parts_update.php',
      data: data
    }).
      then((res) => {

        message.info("操作成功！")
        this.setState({ parts_key_data:targetKeys });
        that.init_data();

      })
  }

  //监听穿梭框
  transfer_change = targetKeys => {
      // console.log(targetKeys)
      
      this.update_daren(targetKeys)
      
  };
  //监听员工分组穿梭框
  transfer_change_parts = targetKeys => {
    // console.log(targetKeys)
    
    this.update_parts(targetKeys)
   
};
// 新增任务
  add_task(){
    let that=this
    // 更新
    if(that.state.task_id!=''){
      that.task_update(that.state.task_id)
      return;
    }
    
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;
    data.account_name=that.state.account_name;
    data.normal_num=that.state.normal_num;
    data.weitao_num=that.state.weitao_num;
    if(data.account_name==undefined){
      message.info("达人账号不能为空")
      return ;
    }
    if(data.normal_num+data.weitao_num>15||data.normal_num+data.weitao_num<=0){
      message.info("总发布数量为0~15")
      return ;
    }
    fn.ajax({
      url: '/task/add.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.init_data();
        that.hidden_task_window();
      })

  }
  // 删除任务
  task_del(record){
    let that=this
    let data = {};
    data.token = that.props.userinfo.token;
    data.task_id=record.id;
    fn.ajax({
      url: '/task/del.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.init_data();
      })
  }

  // 更新任务
  task_update(task_id){
    let that=this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.task_id=task_id;
    data.account_name=that.state.account_name;
    data.normal_num=parseInt(that.state.normal_num);
    data.weitao_num=parseInt(that.state.weitao_num);
    fn.ajax({
      url: '/task/edit.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.init_data();
        that.hidden_task_window(); 
      })

  }


  show_task_window(record=""){
    let that=this;
    console.log(record)
    if(record==""){
      // 新增
      that.setState({
        task_window_visible:true
      })
    }else{
      // 编辑
      that.setState({
        task_window_visible:true,
        task_id:record.id,
        account_name:record.account_name,
        normal_num:record.normal_num,
        weitao_num:record.weitao_num
      })
    }
    
  }

  hidden_task_window(){
    let that=this;
    that.setState({
      task_window_visible:false,
      account_name:undefined,
      normal_num:'',
      weitao_num:'',
      task_id:''
    })
  }

  // 监听达人账号切换
  change_account_name = (value, option) => {
    let that = this;
    that.setState({
      account_name: value
    })
  }


  change_normal_num(value) {
    // const { value } = e.target;
    console.log(value)
    this.setState({
      normal_num: value
    })
  }
  change_weitao_num(value) {
    // const { value } = e.target;
    // console.log(value)
    this.setState({
      weitao_num: value
    })
  }

  // 显示编辑弹框
  show_base_info_window(type){
    let that=this;
    let base_info=that.state.data
    console.log(type)
    that.setState({
      base_info_window_visible:true,
      base_info_type:type,
      alipay:base_info.alipay,
      nickname:base_info.nickname,
      mobile:base_info.mobile,
      identify_card:base_info.identify_card,
    })
  }
  // 隐藏编辑框
  hidden_base_info_window(){
    let that=this;
    that.setState({
      base_info_window_visible:false,
      base_info_type:'',
      alipay:'',
      nickname:'',
      mobile:'',
      identify_card:''
    })
  }
  // 编辑信息
  edit_base_info(){
    let that=this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;

    data.identify_card=that.state.identify_card;
    data.mobile=that.state.mobile;
    data.nickname=that.state.nickname;
    data.alipay=that.state.alipay;
    fn.ajax({
      url: '/privilege/admin_info_update.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.init_data();
        that.hidden_base_info_window(); 
      })
    
  }
  // 监听支付宝账号
  change_alipay(e){
    const { value } = e.target;
    this.setState({
      alipay: value
    })
  }
  // 监听姓名
  change_nickname(e){
    const { value } = e.target;
    this.setState({
      nickname: value
    })
  }
  // 监听身份证
  change_identify_card(e){
    const { value } = e.target;
    this.setState({
      identify_card: value
    })
  }
  // 监听手机号
  change_mobile(e){
    const { value } = e.target;
    this.setState({
      mobile: value
    })
  }
  goback(){
    window.history.go(-1)
  }
  motify_verify_auth(){
    let that=this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.admin_id = that.props.admin_id;
    fn.ajax({
      url: '/privilege/admin_modify_auth.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.init_data();

      })

  }
  render() {
    let that=this
    let data = this.state.data;
    if(!this.props.admin_id){
      return <Redirect to="/privilege/admin_list"/>
    }
    // 统计登录日志
    const login_columns = [
      {
       title: 'IP地址',
       dataIndex: 'ip',
       align: 'center'
 
     }, {
       title: '城市',
       dataIndex: 'city',
       align: 'center'
 
     }, {
       title: '省份',
       dataIndex: 'province',
       align: 'center'
     }, {
       title: '登录时间',
       align: 'center',
       dataIndex: 'create_time',
     }
 
     ];
    //  统计任务信息
    const task_columns = [
      {
       title: '达人账号',
       dataIndex: 'account_name',
       align: 'center'
 
     }, {
       title: '推送微淘与主页（个）',
       dataIndex: 'weitao_num',
       align: 'center'
 
     }, {
       title: '仅发布不推送（个）',
       dataIndex: 'normal_num',
       align: 'center'
     }, {
      title: '规则',
      dataIndex: 'rule',
      align: 'center',
      render:(text)=>{
        return (<span>每天自动下发任务</span>)
      }
    }, {
       title: '创建时间',
       align: 'center',
       dataIndex: 'create_time',
     }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <span>
{/* onConfirm={this.reset_pswd.bind(this, record)} */}
          <Button type="primary" onClick={this.show_task_window.bind(this,record)}  >编辑</Button>
          <Divider type="vertical" />
          <Popconfirm title="确认删除此任务？" okText="是" cancelText="否" onConfirm={this.task_del.bind(this, record)}  >
            <Button type="primary"  >删除</Button>
          </Popconfirm>
        </span>
      )}
 
     ];

    // 角色选择框
    let option_view = null
    if (that.state.daren_list) {
      option_view = that.state.daren_list.map((item) => {
        return (
          <Option value={item.account_name}>{item.account_name}</Option>
        )
      })
    }

    let base_info_view=null
    if(that.state.base_info_type=="MOBILE"){
      base_info_view=<Input placeholder="请输入手机号码" value={this.state.mobile} onChange={this.change_mobile.bind(that)}/>
    }else if(that.state.base_info_type=="CARD"){
      base_info_view=<Input placeholder="请输入身份证号码" value={this.state.identify_card} onChange={this.change_identify_card.bind(that)}/>
    }else if(that.state.base_info_type=="NAME"){
      base_info_view=<Input placeholder="请输入真实姓名" value={this.state.nickname} onChange={this.change_nickname.bind(that)}/>
    }else if(that.state.base_info_type=="ALIPAY"){
      base_info_view=<Input placeholder="请输入支付宝账号" value={this.state.alipay} onChange={this.change_alipay.bind(that)}/>
    }
   
    return (
      <Card className="window-view" title={<div className="window-header-view" >
        <Button type="primary" className="window-close-view" onClick={that.goback.bind(this)}><Icon type='arrow-left' />返回</Button>
        <div className="window-title-view">员工详情</div>
      </div>}>
        <div className="addform-view" >
         

        <Card title="用户信息" className="card-content-view">

          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  账号：
                </Col>
                <Col span={16} className="user-row-value-view">
                {data.username}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  职位：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.name}
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  手机号：
                </Col>
                <Col span={8} className="user-row-value-view">
                  {data.mobile}
                </Col>
                <Col span={8} className="user-row-label-view">
                  <Button type="primary" onClick={that.show_base_info_window.bind(that,"MOBILE")} >修改</Button>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  创建时间：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.create_time}
                </Col>
              </Row>
            </Col>
          </Row>


          <Row justify="center" align="middle" >
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  姓名：
                </Col>
                <Col span={8} className="user-row-value-view">
                  {data.nickname}
                </Col>
                <Col span={8} className="user-row-label-view">
                  <Button type="primary" onClick={that.show_base_info_window.bind(that,"NAME")} >修改</Button>
                </Col>
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  身份证：
                </Col>
                <Col span={8} className="user-row-value-view">
                  {data.identify_card}
                </Col>
                <Col span={8} className="user-row-label-view">
                  <Button type="primary" onClick={that.show_base_info_window.bind(that,"CARD")} >修改</Button>
                </Col>
                
              </Row>
            </Col>
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  支付宝：
                </Col>
                <Col span={8} className="user-row-value-view">
                  {data.alipay}
                </Col>
                <Col span={8} className="user-row-label-view">
                  <Button type="primary" onClick={that.show_base_info_window.bind(that,"ALIPAY")} >修改</Button>
                </Col>
              </Row>
            </Col>
            
          <Col span={6}>
            
            <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  账号状态：
                </Col>
                <Col span={16} className="user-row-value-view">
                  {data.status==0?<span class="primary-font2">禁用</span>:<span class="primary-font1">正常</span>}
                </Col>
              </Row>
          </Col>
            
          </Row>


         <Row justify="center" align="middle" >
         
            <Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                 免审权限：
                </Col>
                <Col span={8} className="user-row-value-view">
                {data.need_verify==0?<span class="primary-font2">禁用</span>:<span class="primary-font1">启用</span>}
                </Col>
                <Col span={8} className="user-row-label-view">
                  <Button type="primary" onClick={that.motify_verify_auth.bind(that)} >{data.need_verify==0?"开启":"关闭"}</Button>
                </Col>
              </Row>
            </Col>
            
            {data.rank<10?<Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                  上级主管：
                </Col>
                <Col span={8} className="user-row-value-view">
                {data.parent_id!=-1?data.parent_nickname:""}
                </Col>
                <Col span={8} className="user-row-label-view">
                 
                </Col>
              </Row>
            </Col>:<Col span={6}>
              <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
            
                </Col>
                <Col span={8} className="user-row-value-view">
              
                </Col>
                <Col span={8} className="user-row-label-view">

                </Col>
              </Row>
            </Col>}
          <Col span={6}>
            
            <Row className="user-row-view" >
                <Col span={8} className="user-row-label-view">
                 
                </Col>
                <Col span={16} className="user-row-value-view">
      
                </Col>
              </Row>
          </Col>
            
          </Row>
      
        </Card>
       
        <Card title="登录日志" className="card-content-view-down">
            <Table columns={login_columns} dataSource={this.state.login_data} className="table-view" />
        </Card>
        {/*员工分配 */}
        {data.rank<=11&&data.rank>=10?<Card title="员工分配" className="card-content-view-down">
          <Transfer
          dataSource={this.state.parts_list}
          showSearch
          listStyle={{
          width: 300,
          height: 350,
          }}
          
          operations={['新增', '移除']}
          targetKeys={this.state.parts_key_data}
          onChange={this.transfer_change_parts}
          render={item => `${item.username}`}
          className="transfer-style"
          />
          <div className="hint-div-style">PS:左侧为可分配员工，右侧为已分配员工</div>
        </Card>:""}
        
        {data.rank==10?'':<Card title="达人配置" className="card-content-view-down">
          <Transfer
          dataSource={this.state.daren_list}
          showSearch
          listStyle={{
          width: 300,
          height: 350,
          }}
          operations={['新增', '移除']}
          targetKeys={this.state.daren_key_data}
          onChange={this.transfer_change}
          render={item =>{
            if(item.tag){
              return (`${item.account_name}（ ${item.tag} ） ( ${item.affect_content_num} )`)
               
            }
            return `${item.account_name} ( ${item.affect_content_num} )`
          } }
          className="transfer-style"
          />
          <div className="hint-div-style">PS:左侧为可选达人账号，右侧为已分配账号</div>
        </Card>}

        
        
        {data.rank==10?'':<Card title="任务配置" className="card-content-view-down">
            <div className="task-add-view">
              <Button type="primary" onClick={this.show_task_window.bind(this,"")} >新增任务</Button>
            </div>
            <Table columns={task_columns} dataSource={this.state.task_data} className="table-view" />
        </Card>}
      </div >

      {/* 任务配置窗口-开始 */}
      <Modal
          title="新增任务"
          visible={this.state.task_window_visible}
          onOk={this.add_task.bind(this)}
          onCancel={this.hidden_task_window.bind(this)}
          okText="提交"
          cancelText="取消"
        >
          {/* <Input placeholder="请输入账号" value={this.state.username} onChange={this.change_username.bind(that)}  disabled={that.state.admin_id?true:false}/> */}
          <Select
            showSearch
            className="model-inpu-style"
            style={{ marginTop: '15px', width: '100%' }}
            placeholder="请选择达人账号"
            optionFilterProp="children"
            onChange={that.change_account_name}
            value={that.state.account_name}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {option_view}
          </Select>
           <InputNumber style={{ marginTop: '15px', width: '100%' }} min={0} max={10} placeholder="请输入发布推送到微淘数量" value={this.state.weitao_num} onChange={this.change_weitao_num.bind(that)} />
           <InputNumber style={{ marginTop: '15px', width: '100%' }} min={0} max={15}  placeholder="请输入仅发布数量" value={this.state.normal_num} onChange={this.change_normal_num.bind(that)} />
        </Modal>
        {/* 任务配置窗口-结束 */}

        {/* 信息编辑窗口-开始 */}
      <Modal
          title="信息修改"
          visible={this.state.base_info_window_visible}
          onOk={this.edit_base_info.bind(this)}
          onCancel={this.hidden_base_info_window.bind(this)}
          okText="提交"
          cancelText="取消"
        >
          {base_info_view}
        
        </Modal>
        {/* 信息编辑窗口-结束 */}

        
      </Card>
    );
  }
}

Admin_detail = Form.create()(Admin_detail);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
    admin_id: state.admin_id
  }
};
export default connect(mapStateToProps)(Admin_detail)