import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Withdraw_list extends React.Component {
  state = {

    refund_amount:undefined,
    current_record:undefined,
    refund_modal_visible:false,

    current_page:1,
    order_list: [],
    total_count:undefined,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    account_name:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();

  }
  // 搜索
  search = (mode=0) => {
    let that=this;
    let data = this.props.form.getFieldsValue();
    if(mode==1){
      this.init_data(data.username,that.state.current_page);
    }else{
      this.init_data(data.username,1);
    }
    
  }
 // 监听页脚切换
 change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.username,page);
  }

  // 初始化数据
  init_data(username="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.username=username;
    data.p=page

    fn.ajax({
      url: 'withdraw/list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          total_count: res.data.count,
          order_list:res.data.list
        })
      })
  }

  // 申请退款
  verify(record,mode){
    let that=this
    let withdraw_id=record.id;

    let data = {};
    data.token = that.props.userinfo.token;
    data.withdraw_id=withdraw_id;
    data.mode=mode;
    fn.ajax({
      url: '/withdraw/verify.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.search(1)
      })
  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
    },{
      title: '申请账号',
      dataIndex: 'username',
      align: 'center',
      render: (text, record, index) => {
         return <text>{record.realname}（{record.username}）</text>
      }
    },{
      title: '提现类型',
      dataIndex: 'type',
      align: 'center',
      render: (text, record, index) => {
        if(text=="WXPAY"){
          return <text>微信</text>
        }else if(text=="ALIPAY"){
          return <text>支付宝</text>
        }
     }
    }, {
      title: '提现金额（元）',
      dataIndex: 'amount',
      align: 'center',
      render: (text, record, index) => {
        let amount=parseFloat(text)
         return <text className="primary-font-bold">{amount}</text>
      }
    },{
      title: '订单状态',
      dataIndex: 'status',
      align: 'center',
      render: (text, record, index) => {
      
        if(text==0){
          return <span>等待审核</span>
        }else if(text==1){
          return <span className="income-font">通过</span>
        }else if(text==2){
          return <span className="pay-font">失败</span>
        }
        
      }
    },
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
          return <div>
            {record.status==0?<Popconfirm title="确认通过该申请？" okText="是" cancelText="否" onConfirm={this.verify.bind(this,record,1)}><Button type="primary" style={{ marginRight:"30px"}} >通过</Button></Popconfirm> :''}
            {record.status==0?<Popconfirm title="确认拒绝该申请？" okText="是" cancelText="否" onConfirm={this.verify.bind(this,record,2)}><Button type="primary" >拒绝</Button></Popconfirm>:''}
        </div>   
     
 
      }
    }
    ];
    
    return (
      <div >
        

        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="手机号">
            {
              getFieldDecorator('username', {

              })(
                <Input placeholder="请输入手机号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>      
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <Table columns={columns} style={{"marginTop":"20px"}} dataSource={this.state.order_list} className="table-view"  pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }}/>

       {/* 订单退款*/}
       {this.state.refund_modal_visible?<Modal
          title={"申请退款 - 订单"+that.state.current_record.username}
          visible={this.state.refund_modal_visible}
          onOk={this.refund.bind(that)}
          onCancel={this.refund_modal_hidden.bind(that)}
          okText="提交"
          cancelText="取消"
        >
          <InputNumber placeholder="请输入退款金额"  min={0.01} max={(that.state.current_record.pay_amount-that.state.current_record.refund_amount).toFixed(2)}  value={this.state.refund_amount} onChange={this.change_refund_amount.bind(that)} style={{"width":"100%"}}  />
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 当前订单最高可退款：{(that.state.current_record.pay_amount-that.state.current_record.refund_amount).toFixed(2)} 元</div>
        </Modal>:''}
       


      </div>

    );
  }
}

Withdraw_list = Form.create()(Withdraw_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Withdraw_list)