import React, { Component } from 'react';
import { Row, Col } from 'antd';
import { Redirect, NavLink } from 'react-router-dom'
import './style/common.less';
import './style/loading.less';
import { connect } from "react-redux"
import Header from './components/Header/index'
import NavLeft from './components/NavLeft/index'

class Admin extends Component {
  state={
 
  }
  // 
  componentWillMount() {

  }


  render() {
    return (
      <Row className="container">
          <Col span={4} className="nav-left">
            <NavLeft></NavLeft>
          </Col>
          <Col span={20} className="main">
            <Header className="top"></Header>
            <Row className="down">
              {this.props.children}
            </Row>
          </Col>
          
        </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Admin)


