

const menuList = [
];


const menurootList = [
    // {
    //     "title": "首页",
    //     "key": "/statistic/gather",
    //     "icon": "changjiangjackshouye"
    // },
    {
        "title": "商户管理",
        "key": "/shop",
        "children": [
            {
                "title": "商户列表",
                "key": "/shop/list"
            }
        ],
        "icon": "changjiangjackshanghu"
    },

    {
        "title": "角色管理",
        "key": "/role",
        "children": [
            {
                "title": "角色列表",
                "key": "/role/role_list"
            }
        ],
        "icon": "changjiangjackjiaose"
    }
];
const mymenurootList = [

];

export { menuList,menurootList,mymenurootList };

