import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { TreeSelect ,Table, Divider, Button, Card, message, Popconfirm, Form, Input, Modal, InputNumber } from 'antd';
import { connect } from "react-redux"
import '../../../style/antd_table.less'
const FormItem = Form.Item;
const { SHOW_PARENT } = TreeSelect;

class  Role_list extends React.Component {
  state = {
    data: [],
    role_id:'',
    title:'',
    rank:'',
    modal_label: '',
    modal_visible:false,
    privilege_modal_label: '',
    privilege_modal_visible:false,
    role_privilege: [],
    privilege_data:[]
  }
  // 搜索
  search = () => {
    let data = this.props.form.getFieldsValue();
    this.init_data(data.title);
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();
    this.init_privilege_data();
  }
  // 初始化数据
  init_privilege_data() {
    fn.ajax({
      url: '/privilege/get.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        this.setState({
          privilege_data: res.data
        })
      })
  }
  // 初始化数据
  init_data(title='') {
    fn.ajax({
      url: '/privilege/role_list.php',
      data: {
        token: this.props.userinfo.token,
        title:title
      }
    }).
      then((res) => {
        this.setState({
          data: res.data,

        })
      })
  }

  // 新增角色
  submit_role = () => {
    let that = this;
    let data = {};
    let url = '/privilege/role_add.php';
    data.token = that.props.userinfo.token;
    data.title = that.state.title;
    data.role_id=that.state.role_id;
    data.rank=that.state.rank;
    // 校验参数
    if (!data.title) {
      message.error("角色不能为空");
      return false;
    } 
    if (!data.rank) {
      message.error("角色级别不能为空");
      return false;
    } 
    if (data.role_id) {
      url = '/privilege/role_edit.php';
    }
    fn.ajax({
      url: url,
      data: data
    }).
      then((res) => {
        if (!data.role_id) {
          message.success("新增成功!");
        } else {
          message.success("保存成功!");
        }
        // 隐藏对话框
        that.modal_hidden();
        that.init_data();
      })
  }
  // 修改权限
  submit_privilege = () => {
    let that = this;
    let data = {};
    let url = '/privilege/privilege_edit.php';
    data.token = that.props.userinfo.token;
    data.role_privilege=that.state.role_privilege;
    data.role_id=that.state.role_id;
 
    fn.ajax({
      url: url,
      data: data
    }).
      then((res) => {

        message.success("配置权限成功!");
        // 隐藏对话框
        that.privilege_modal_hidden();
        that.init_data();
      })
  }

  // 移除角色
  delete_role(record) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.role_id = record.id;
    fn.ajax({
      url: '/privilege/role_del.php',
      data: data
    }).
      then((res) => {
        message.success("移除成功!");
        // 隐藏对话框
        that.init_data();
      })
  }

  // 新增
  add() {
    let that = this;
    that.setState({
      modal_visible: true,
      modal_label: '新增角色'
    })
  }
  // 修改
  edit(data, index) {
    let that = this;
    let role_id = data.id;
    let rank=data.rank;
    // 获取当前角色值
    that.setState({
      role_id: role_id,
      modal_visible: true,
      modal_label: '修改角色',
      title:data.name,
      rank:rank
    })
  }
  // 权限配置
  edit_auth(data,index){
    let that = this;
    let role_id = data.id;
    let privilege_id=data.privilege_id;

    that.setState({
      role_id: role_id,
      role_privilege:privilege_id,         
      privilege_modal_visible: true,
      privilege_modal_label: '角色权限配置'
    })
  }
  //隐藏角色权限弹出框
  privilege_modal_hidden = () => {
    let that = this;
    that.setState({
      privilege_modal_visible: false,
      privilege_modal_label: '',
      role_privilege:[],
      role_id:''
    })
  }

  
  //隐藏弹出框
  modal_hidden = () => {
    let that = this;
    that.setState({
      modal_visible: false,
      modal_label: '',
      title:"",
      rank:'',
      role_id:''
    })
  }
  // 监听角色
  change_title(e) {
    const { value } = e.target;
    this.setState({
      title: value
    })
  }
  // 选择级别
  change_rank(e){
    // const { value } = e.target;
    console.log(e)
    this.setState({
      rank: e
    })
  
  }
  // 监听角色权限配置
  change_role_privilege = value => {
    console.log(value);
    this.setState({ role_privilege:value });
  }


  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '编号',
      dataIndex: 'id',
      align: 'center',
      render:(text,record,index)=>{
         
          return <span>{index+1}</span>;
      }
    }, {
      title: '角色',
      dataIndex: 'name',
      align: 'center'

    },{
      title: '级别',
      dataIndex: 'rank',
      align: 'center'

    },{
      title: '权限',
      dataIndex: 'privilege_data',
      align: 'center',
      render:(arr)=>{
          let auth_str=arr.join(' , ');
          if(!auth_str) auth_str="暂无权限";
          return <span>{auth_str}</span>;
      }

    },{
      title: '创建时间',
      align: 'center',
      dataIndex: 'create_time',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => (
        <span>
          <Button type="primary" onClick={this.edit.bind(this, record, index)} >编辑</Button>
          <Divider type="vertical" />
          <Button type="primary" onClick={this.edit_auth.bind(this, record, index)} >权限配置</Button>
          <Divider type="vertical" />
          <Popconfirm title="确认移除该角色？" okText="是" cancelText="否" onConfirm={this.delete_role.bind(this, record)}>
            <Button type="primary">移除</Button>
          </Popconfirm>

        </span>
      ),
    }
    ];
    return (

      <div >
        <Form layout="inline" className="main-headerform-view">
          <FormItem label="角色">
            {
              getFieldDecorator('title', {

              })(
                <Input placeholder="请输入角色" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>

          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <div className="main-add-view">
          <Button type="primary" onClick={this.add.bind(this)} >新增角色</Button>
        </div>
        <Table columns={columns} dataSource={this.state.data} className="table-view" />

        {/* 新增角色组件 begin*/}
        <Modal
          title={this.state.modal_label}
          visible={this.state.modal_visible}
          onOk={this.submit_role}
          onCancel={this.modal_hidden}
          okText="提交"
          cancelText="取消"
        >
          <Input placeholder="请输入角色" value={this.state.title} onChange={this.change_title.bind(that)}  />
          <InputNumber min={1} max={100} placeholder="请输入级别 (1-99之间，数字越大级别越高)" value={this.state.rank} onChange={this.change_rank.bind(that)} style={{ marginTop: '15px', width: '100%' }} />

       </Modal>
        {/* 新增角色组件 end*/}
        {/* 权限配置组件 begin*/}
        <Modal
          title={this.state.privilege_modal_label}
          visible={this.state.privilege_modal_visible}
          maskClosable={false}
          onOk={this.submit_privilege}
          onCancel={this.privilege_modal_hidden}
          okText="提交"
          cancelText="取消"
        >
          <TreeSelect 
            treeData={that.state.privilege_data}
            value= {this.state.role_privilege}
            onChange={this.change_role_privilege}
            treeCheckable={true}
            showCheckedStrategy={SHOW_PARENT}
            searchPlaceholder="请选择权限"
            className="model-inpu-style"
          />

       </Modal>
       {/* 权限配置组件 end*/}
      </div>

    );
  }
}

 Role_list = Form.create()( Role_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)( Role_list)