import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as fn from '../../../utils/index'
import * as CONFIG from '../../../config/config';
import {
  Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
} from 'antd';
import { MinusOutlined,PlusOutlined } from '@ant-design/icons'
import AutoComplete from 'react-bmapgl/Services/AutoComplete'

const { Step } = Steps;
const Option = Select.Option;
const { TextArea } = Input;

const { TabPane } = Tabs;
class Scene_add extends React.Component {
  state = {
    admin_info:{},
    type_list:[],
    shop_account:[],
    all_account:[],
    plan_data:[],
    role_data:[],
    plan_type:1,
    role_id:undefined,
    address:'',
    role_id:undefined,
    username:undefined,
    realname:undefined,
    city:'',
    address_key:''
  };

  componentDidMount() {
    let that=this
    // 获取场景类型
    that.init_scene_type();
    // 获取可操作角色
    that.init_role_data();
    // 获取商家账号
    that.get_shop_account();
    // 获取所有账号
    that.get_all_account();
    // 获取当前账号的分成比例
    that.get_admin_info();

    that.props.form.setFieldsValue({
      plan_type:1 
    })
  }

  get_admin_info(){
    let that=this
    fn.ajax({
      url: '/privilege/admin_get.php',
      data: {
        token: that.props.userinfo.token
      }
    }).
      then((res) => {

        that.setState({

          admin_info: res.data,
        })
      })
  }
  
  // 返回上一页
  goback(){
    let that=this
    fn.page_back();
  }
  // 初始化角色数据
  init_role_data() {
    let that=this;
    fn.ajax({
      url: '/privilege/role_list.php',
      data: {
        token: this.props.userinfo.token
      }
    }).
      then((res) => {
        that.setState({
          role_data: res.data,
        })
      })
  }
  // 获取所有店长账号
  get_shop_account(){
    let that=this;
    fn.ajax({
      url: '/privilege/admin_list.php',
      data: {
        token: that.props.userinfo.token,
        role_id: 7,
        page_size:1000
      }
    }).
      then((res) => {
        that.setState({
          shop_account: res.data.list
        })
   
      })
  }
  // 获取所有子账号
  get_all_account(){
    let that=this;
    fn.ajax({
      url: '/privilege/admin_list.php',
      data: {
        token: that.props.userinfo.token,
        page_size:1000
      }
    }).
      then((res) => {
        this.setState({
          all_account: res.data.list,
        })
      })

  }
  // 初始化场景类型
  init_scene_type(){
    let that=this
    let data={}
    data.token=this.props.userinfo.token;
    fn.ajax({
      url: '/scene/type_list.php',
      data: data
    }).
      then((res) => {
        that.setState({
          type_list:res.data
        })
      })
  }


  // 添加场所
  add_scene(){
    let that=this
    let formdata = that.props.form.getFieldsValue();

    that.props.form.validateFields((err, values) => {
      if (!err) {

        // 校验分成比例
        let plan_data=formdata.plan_data;
        if(plan_data.length==0){
            message.error("分成方案不能为空");
            return false;
        }
        let rate=0;
        for(let i=0;i<plan_data.length;i++){
          if(!plan_data[i].id||!plan_data[i].rate){
            message.error("分成方案不能为空");
            return false;
          }
          rate+=plan_data[i].rate;
        }
        let plan_rate=parseFloat(Number(that.state.admin_info.plan_rate).toFixed(2))
        // 校验比例是否合法
        if(rate>plan_rate){
          message.error("分成比例不可超过"+plan_rate+"%");
          return false;
        }

        let address_key=that.state.address_key;
        let city=that.state.city;
    
        let data={}
        data.token=this.props.userinfo.token;
        data.name=formdata.name;
        data.type=formdata.type;
        data.address=formdata.address;
        data.city=city;
        data.address_key=address_key;
        data.scene_admin_id=formdata.scene_admin_id;
        data.plan_type=formdata.plan_type;
        data.plan_data=JSON.stringify(formdata.plan_data)
        fn.ajax({
          url: '/scene/add.php',
          data: data
        }).
          then((res) => {
              message.success(`操作成功`);
              fn.page_back();
          })
      }
    });
  }

  
 // 监听地址
 change_address=(e)=>{
  let that=this
  const {value}=e.target;

  that.setState({
      address:value
  })
  that.props.form.setFieldsValue({
    address:address
  })
}
// 获取提交地址
autocomplete_confirm(e){
  let that=this
  let value=e.item.value;
  let address=value.city+value.district+value.business;
  that.setState({
      address:address,
      city:value.city,
      address_key:value.business
  })
  that.props.form.setFieldsValue({
    address:address
  })
}
// 新增分成方案
add_plan_row(){
  let that=this
  let plan_data=that.state.plan_data;
  plan_data.push({});
  that.setState({
    plan_data:plan_data
  })

}
// 移除分成方案
remove_plan_row(index){
  let that=this
  let plan_data=that.state.plan_data;
  plan_data.splice(index,1)
  that.setState({
    plan_data:plan_data
  })
}

change_cost_rate=(index,value)=>{
  let that=this;
  let plan_data=that.state.plan_data;

  plan_data[index].cost_rate=value;
  that.setState({
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_data:plan_data
  })
}


// 新增
modal_show(role_id) {
  let that = this;
  that.setState({
    modal_visible: true,
    role_id:role_id
  })
}
//隐藏弹出框
modal_hidden = () => {
  let that = this;
  that.setState({
    modal_visible: false,
    username: undefined,
    realname:undefined
  })
}
// 监听账号
change_username(e) {
  const { value } = e.target;
  let username=fn.filter_string(value)
  // 只能输入字母、数字
  this.setState({
    username: username
  })

}
// 监听真实名称
change_realname(e){
  let that=this
  const { value } = e.target;
  that.setState({
    realname: value
  })
}

// 监听角色
change_role = (value, option) => {
  let that = this;
  console.log(value)
  that.setState({
    role_id: value
  })
  
}
  // 新增账号
  add_admin = () => {
    let that = this;
    let data = {};
    let url = '/privilege/admin_add.php';
    data.token = that.props.userinfo.token;
    data.username = that.state.username;
    data.realname=that.state.realname;
    data.role_id=that.state.role_id
    if(!data.role_id){
      message.error("角色不能为空");
      return false;
    }
    // 校验参数
    if (!data.username) {
      message.error("手机号不能为空");
      return false;
    }
    if (!data.realname) {
      message.error("姓名不能为空");
      return false;
    }
    fn.ajax({
      url: url,
      data: data
    }).
      then((res) => {
        message.success("操作成功!");
        // 隐藏对话框
        that.modal_hidden();
        // 获取商家账号
        that.get_shop_account();
        that.get_all_account();
      })
  }
  // 监听账号切换
  change_shop_account= (value, option) => {
      let that=this;
      let plan_data=that.state.plan_data
      let tmp={}
      tmp.id=value;
 
      if(plan_data.length>0){
        plan_data[0]=tmp;
      }else{
        plan_data.push(tmp);
      }
      that.setState({
        plan_data:plan_data
      })
      that.props.form.setFieldsValue({
        plan_data:plan_data
        
      })

  }
  // 监听分成账号
  change_plan_account=(index,value)=>{
    let that=this;
    let plan_data=that.state.plan_data;
    plan_data[index].id=value;
    that.setState({
      plan_data:plan_data
    })
    that.props.form.setFieldsValue({
      plan_data:plan_data
    })

  }
  // 监听分成比例
  change_plan_rate(index,value){
    let that=this
    let plan_data=that.state.plan_data;
    plan_data[index].rate=value;
    that.setState({
      plan_data:plan_data
    })
  }
 // 监听来源
 change_plan_type= (e, option) => {
  let that=this
  let plan_data=that.state.plan_data;

  that.setState({
    plan_type:e.target.value,
    plan_data:plan_data
  })
  that.props.form.setFieldsValue({
    plan_type:e.target.value,
    plan_data:plan_data
  })
}

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;

    // 获取当前总分成比例

    let plan_rate='';
    if(that.state.admin_info.plan_rate){
      plan_rate=parseFloat(Number(that.state.admin_info.plan_rate).toFixed(2));
    }
    let type_view=null;
    if(that.state.type_list){
      type_view=that.state.type_list.map((item)=>{
        return <Option value={item.id} key={item.key}>{item.name}</Option>
      })
    }
    // 角色选择框
    let option_view = null
    if (that.state.role_data) {
      option_view = that.state.role_data.map((item) => {
        return (
          <Option value={item.id}>{item.name}</Option>
        )
      })
    }

    
    let plan_view=null;
    let shop_account_view=null;
    // 获取店长组件
    if(that.state.shop_account){
      shop_account_view=that.state.shop_account.map((item)=>{
        return <Option value={item.id}>{item.realname} - {item.username}</Option>
      })
    }
    // 获取全部账号组件
    let all_account_view=null;
    if(that.state.all_account){
      all_account_view=that.state.all_account.map((item)=>{
        return <Option value={item.id}>{item.realname} - {item.username}</Option>
      })
    }
    // 获取方案组件
    if(that.state.plan_data){
      plan_view=that.state.plan_data.map((item,index)=>{
        return <div className="scene-plan-row-view">
        <Select
          showSearch
          style={{ width: '250px' }}
          placeholder="请选择分成账号"
          optionFilterProp="children"
          disabled={index==0?true:false}
          onChange={that.change_plan_account.bind(that,index)}
          value={item.id}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }>
          {all_account_view}
        </Select>
        <InputNumber placeholder="请输入分成比例" value={item.rate} max={100} min={1} className="margin-left20" style={{width:"160px",marginLeft:"20px"}}  onChange={that.change_plan_rate.bind(that,index)} /> %
        {that.state.admin_info.has_good==1?<InputNumber placeholder="请输入成本比例" value={item.cost_rate} max={100} min={1} className="margin-left20" style={{width:"160px",marginLeft:"20px"}}  onChange={that.change_cost_rate.bind(that,index)} />:'' }
        {that.state.admin_info.has_good==1?' %':'' }
 
        {index==0?<Button type="primary" onClick={this.add_plan_row.bind(this)} className="margin-left20"> <Icon type="plus" />新增</Button>:
        <Button type="primary" onClick={this.remove_plan_row.bind(this,index)} className="margin-left20"> <MinusOutlined />移除</Button>}
      </div>
      }) 
    }

    return (
      <Card  className="window-view " title={<div className="window-header-view" >
        <Button type="primary" className="window-close-view" onClick={that.goback.bind(this)}><Icon type='arrow-left' />返回</Button>
        <div className="window-title-view">新增场所</div>
      </div>}>
        <div  className="addform-view">
         <Form className="addscene-window-view">
           <div className="addscene-top-wrap-view">

              <div className="addscene-cardform-head-view">
                <div className="addscene-hint-view" >
                基本信息
                </div>
                <Form.Item
                label="名称" className="myformitem-row"
              >
                {getFieldDecorator('name', {
                  rules: [{
                    required: true, message: '请输入1-50字的字符',
                  }],
                })(
                  <Input placeholder="地区+场所名称+场所标识" maxLength={50} minLength={1} style={{width:"300px"}} />
                )}
              </Form.Item>

              <Form.Item
                label="类型" className="myformitem-row"
              >
                {getFieldDecorator('type', {
                  rules: [{
                    required: true, message: '场所类型不能为空',
                  }],
                })(
                  <Select
                  showSearch
                  style={{ width: '300px' }}
                  placeholder="请选择场所类型"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }>
                  {type_view}
                </Select>
                )}
              </Form.Item>
              <Form.Item
                label="地址"  className="myformitem-row"
              >
                {getFieldDecorator('address', {
                  rules: [{
                    required: true, message: '场所地址不能为空',
                  }],
                })(
                  <div></div>
                )}
                <Input  placeholder="请输入场所地址" value={that.state.address} onChange={that.change_address.bind(that)}   style={{width:"300px"}} id="scene-address"/>
                <AutoComplete input="scene-address" onConfirm={that.autocomplete_confirm.bind(that)}/> 
              </Form.Item>
              
              <Form.Item
                label="店长" className="myformitem-row"
              >
                {getFieldDecorator('scene_admin_id', {
                  rules: [{
                    required: true, message: '店长不能为空',
                  }],
                })(

                  <Select
                    showSearch
                    style={{ width: '300px' }}
                    placeholder="请选择店长账号"
                    onChange={that.change_shop_account.bind(that)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {shop_account_view}
                  </Select>

                )}
                <Button type="primary" onClick={this.modal_show.bind(this,7)} className="margin-left20"> <PlusOutlined />新增店长</Button>
                <Button type="primary" onClick={this.modal_show.bind(this,undefined)} className="margin-left20"> <PlusOutlined />新增账号</Button>
              </Form.Item>

            </div>
           <div className="addscene-cardform-head-view margin-top50" hidden={that.state.plan_data.length>0?false:true}>
                <div className="addscene-hint-view" >
                分成方案
                </div>
                <Form.Item
                label="类型" className="myformitem-row"
              >
                {getFieldDecorator('plan_type', {
                  rules: [{
                    required: true, message: '分成类型不能为空',
                  }],
                })(
                  <div></div>
                )}
                <Radio.Group  buttonStyle="solid" value={that.state.plan_type} onChange={that.change_plan_type} >
                    <Radio.Button value={1}>营业额分成</Radio.Button>
                    <Radio.Button value={2}>利润分成</Radio.Button>
                  </Radio.Group> 
              </Form.Item>
                <Form.Item
                label="账号" className="myformitem-row2"
                extra={"多个账号的分成比例不可超过"+plan_rate+"%，成本比例不可超过100%"}
              >
                {getFieldDecorator('plan_data', {
                  rules: [{
                    required: true, message: '分成方案不能为空',
                  }],
                })(
                  <div></div>
                )}
          
                {plan_view}
              </Form.Item>
            </div>
           </div>
          <Form.Item className="layout-center">
                  <Button type="primary" onClick={this.add_scene.bind(this)}>提交</Button>
                </Form.Item>
        </Form>
        </div>


        {/* 新增账号组件 begin*/}
        <Modal
          title="新增账号"
          visible={this.state.modal_visible}
          onOk={this.add_admin}
          onCancel={this.modal_hidden}
          maskClosable={false}
          okText="提交"
          cancelText="取消"
        >

          <Input placeholder="请输入姓名" value={this.state.realname} onChange={this.change_realname.bind(that)} />
          <Input placeholder="请输入手机号" value={this.state.username} onChange={this.change_username.bind(that)}   style={{ marginTop: '15px', width: '100%' }}/>
          <Select
            showSearch
            className="model-inpu-style"
            style={{ marginTop: '15px', width: '100%' }}
            placeholder="请选择角色"
            optionFilterProp="children"
            onChange={that.change_role}
            value={that.state.role_id}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }>
            {option_view}
          </Select>
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 手机号为登录账号！</div>
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 新账号初始密码为888888，可自行更改！</div>
        </Modal>
      </Card>
    );
  }
}

Scene_add = Form.create()(Scene_add);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo,
  }
};
export default connect(mapStateToProps)(Scene_add)


