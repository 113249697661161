import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Order_list extends React.Component {
  state = {

    refund_amount:undefined,
    current_record:undefined,
    refund_modal_visible:false,

    current_page:1,
    order_list: [],
    total_count:undefined,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    account_name:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();

  }
  // 搜索
  search = (mode=0) => {
    let that=this;
    let data = this.props.form.getFieldsValue();
    if(mode==1){
      this.init_data(data.out_trade_no,data.device,data.scene,that.state.current_page);
    }else{
      this.init_data(data.out_trade_no,data.device,data.scene,1);
    }
    
  }
 // 监听页脚切换
 change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.out_trade_no,"","",page);
  }

  // 初始化数据
  init_data(out_trade_no="",device_name="",scene_name="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.out_trade_no=out_trade_no;
    data.device_name=device_name;
    data.scene_name=scene_name;
    data.p=page

    fn.ajax({
      url: '/order/list.php',
      data: data
    }).
      then((res) => {
        this.setState({
          total_count: res.data.count,
          order_list:res.data.list
        })
      })
  }

  
  // 监听退款金额
  change_refund_amount(value){
    let that=this
    // console.log(value)
    that.setState({
      refund_amount:value
    })

  }
  // 隐藏退款窗口
  refund_modal_hidden(){
    let that=this
    that.setState({
      current_record:undefined,
      refund_amount:undefined,
      refund_modal_visible:false
    })
    
  }
  // 显示退款窗口
  refund_modal_show(record){
    let that=this
    that.setState({
      current_record:record,
      refund_amount:undefined,
      refund_modal_visible:true
    })
  }
  // 申请退款
  refund(){
    let that=this
    let refund_amount=that.state.refund_amount
    if(!refund_amount){
      message.error("退款金额不能为空！")
      return false;
    }

    confirm({
      title: '该操作不可逆，且会影响资金变化！确认继续?',
      icon: <ExclamationCircleOutlined />,
      okText:'确认',
      cancelText:'取消',

      content: '点击确认后将进行资金退款操作！',
      onOk() {
        let data = {};
        data.token = that.props.userinfo.token;
        data.order_id=that.state.current_record.id;
        data.refund_amount=refund_amount;
        fn.ajax({
          url: '/order/refund.php',
          data: data
        }).
          then((res) => {
            message.success("操作成功！")
            that.search(1)
            that.refund_modal_hidden()
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
   
  }
  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '订单号',
      dataIndex: 'out_trade_no',
      align: 'center',
    },{
      title: '场所名称',
      dataIndex: 'scene_name',
      align: 'center',
    },{
      title: '设备名称',
      dataIndex: 'device_name',
      align: 'center',
    }, {
      title: '商品主图',
      dataIndex: 'good_pic',
      align: 'center',
      render: (text, record, index) => {
         return <img src={text} className="good-main-pic-view"/>
      }
    }, {
      title: '商品标题',
      dataIndex: 'good_title',
      align: 'center',
      render: (text, record, index) => {
         return <span >{text}</span>
      }
    },{
      title: '订单状态',
      dataIndex: 'status',
      align: 'center',
      render: (text, record, index) => {
      
        if(text=="WAIT"){
          return <span>等待支付</span>

        }else if(text=="SUCCESS"){
          return <span className="income-font">支付成功</span>
        }else if(text=="REFUND_WAIT"){
          return <span>等待退款</span>
        }else if(text=="REFUND_SUCCESS"){
          return <span className="pay-font">退款成功</span>
        }
        
      }
    },{
      title: '订单总金额（元）',
      dataIndex: 'total_amount',
      align: 'center',
      render: (text, record, index) => {
        text=parseFloat(text)
        text=text.toFixed(2)
          return <span className="income-font ">{text}</span>
      }
    },
    
    {
      title: '实收金额（元）',
      dataIndex: 'pay_amount',
      align: 'center',
      render: (text, record, index) => {
        text=parseFloat(text)
        text=text.toFixed(2)
        if(record.status=="SUCCESS"){
          return <span className="income-font ">{text}</span>

        }else{
          return <span></span>
        }
      }
    },{
      title: '退款总金额（元）',
      dataIndex: 'refund_amount',
      align: 'center',
      render: (text, record, index) => {
        text=parseFloat(text)
        text=text.toFixed(2)
        if(text>0){
          return <span className="pay-font ">{text}</span>

        }else{
          return <span>暂无</span>
        }
      }
    },
    
    {
      title: '创建时间',
      dataIndex: 'create_time',
      align: 'center'
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        if(that.props.userinfo.role_id==9){
          if(record.status=="SUCCESS"&& record.refund_amount==0){
            return <div>
          <Button type="primary" onClick={that.refund_modal_show.bind(that,record)}>退款</Button>
          </div>   
          }
          // if(record.status=="SUCCESS"&& record.pay_amount>record.refund_amount){
          //   return <div>
          // <Button type="primary" onClick={that.refund_modal_show.bind(that,record)}>退款</Button>
          // </div>   
          // }
        }
        return <div>暂无</div>   
      }
    }
    ];
    
    return (
      <div >
        

        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="订单号">
            {
              getFieldDecorator('out_trade_no', {

              })(
                <Input placeholder="请输入订单号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="场所">
            {
              getFieldDecorator('scene', {

              })(
                <Input placeholder="请输入场所" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="设备">
            {
              getFieldDecorator('device', {

              })(
                <Input placeholder="请输入设备" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
       
        
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <Table columns={columns} style={{"marginTop":"20px"}} dataSource={this.state.order_list} className="table-view"  pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }}/>

       {/* 订单退款*/}
       {this.state.refund_modal_visible?<Modal
          title={"申请退款 - 订单"+that.state.current_record.out_trade_no}
          visible={this.state.refund_modal_visible}
          onOk={this.refund.bind(that)}
          onCancel={this.refund_modal_hidden.bind(that)}
          okText="提交"
          cancelText="取消"
        >
          <InputNumber placeholder="请输入退款金额"  min={0.01} max={(that.state.current_record.pay_amount-that.state.current_record.refund_amount).toFixed(2)}  value={this.state.refund_amount} onChange={this.change_refund_amount.bind(that)} style={{"width":"100%"}}  />
          <div style={{ marginTop: '15px', width: '100%' }} className="model-hint-font"> * 当前订单最高可退款：{(that.state.current_record.pay_amount-that.state.current_record.refund_amount).toFixed(2)} 元</div>
        </Modal>:''}
       


      </div>

    );
  }
}

Order_list = Form.create()(Order_list);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Order_list)