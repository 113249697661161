
import React from 'react'
import './index.less'
import { connect } from "react-redux"
import * as CONFIG from '../../config/config';

import {
    Form, Input, Icon, Radio, Select, InputNumber,Modal, message, Button, Card,Upload,Divider, Steps,Switch,Table,List,Popconfirm,Tag,Tabs
  } from 'antd';
class Home extends React.Component {
   
    state = {  
        text:'',
        address:''
    };
    // 监听地址
    change_address=(e)=>{
        let that=this
        const {value}=e.target;
        console.log(value)
        that.setState({
            address:value
        })
    }
    // 获取提交地址
    autocomplete_confirm(e){
        let value=e.item.value;
        let address=value.city+value.district+value.business;
        that.setState({
            address:address
        })
    }

    render() {
        let that=this;

        return (
            <div  className="home-wrap">
                欢迎使用{this.props.userinfo.company}管理后台
            </div>
        );
  
    }
}


const mapStateToProps = state => {

    return {
        userinfo: state.userinfo
    }
};
export default connect(mapStateToProps)(Home)



