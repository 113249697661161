import React from 'react'
import './index.less'
import { connect } from "react-redux"

import { Redirect ,NavLink} from 'react-router-dom'
import * as fn from '../../../utils/index'
import { login } from '../../../redux/action'
import cookie from 'react-cookies'
import {
  Form,Table, Input, Icon, Upload, Select, Modal, message, Button, InputNumber, Card, Switch
} from 'antd';

import { Chart } from '@antv/g2';



class Statistic_gather extends React.Component {

  state = {
    data:{},
    content:{},
    goto_create_draft:false,
    user_info:null,
    income:{},
    task_data:[]
  };

  componentDidMount() {
    let that = this;
    // that.init_static_view();
    that.init_data();
  }


  // 初始化数据
  init_data(){
    let that=this
    let data = {};
    data.token = that.props.userinfo.token;
    fn.ajax({
      url: '/statistic/shop.php',
      data: data
    }).
      then((res) => {
        that.setState({
          content:res.data

        })
    
      })
  }

  init_static_view(list=[],views=[]){
     
    let that=this;
    let  data = [];
    for(let i =0;i<=29;i++){
        let tmp={};
        let tmp2={};
        
        
        tmp.date=fn.getDay(i-30);
        tmp.value=parseInt(Math.random()*100);
 
        tmp.play=parseInt(Math.random()*100);

        data.push(tmp); 
        
    }
    let chart = new Chart({
      container: "income-play",
      width:1500,
      height:350
    });


  
    chart.source(data);
    chart.axis('value', false);
    chart.axis('play', false);
    chart.scale({'play':{
      alias: '订单数量'
    },'value':{
      alias: '订单金额'
    }
  
  });
    chart.legend({
      position: 'bottom'
    });
    chart.tooltip({
      showCrosshairs: true,
      shared: true
    });
    

    // chart.area().position('date*value').color("type");
    // chart.size(10)
    chart.area().position('date*value').shape('smooth');
    chart.area().position('date*play').color('#1da57a').shape('smooth');
    chart.render();
    
  }
  render() {
    let that=this;
    if(that.state.goto_create_draft){
      return <Redirect to="/content/create_draft"/>
    }
    let data=that.state.data;
    let income=that.state.income
    // console.log(data)

    const columns = [{
      title: '账号',
      dataIndex: 'username',
      align: 'center',
    }, {
      title: '姓名',
      dataIndex: 'nickname',
      align: 'center'

    },{
      title: '本月投稿量',
      dataIndex: 'month_total',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.month_total - b.month_total,
      render: (text, record, index) => (
        <span className="income-font">{text}</span>
     )
    },{
      title: '本月通过量',
      dataIndex: 'month_success',
      align: 'center',
      sorter: (a, b) => a.month_success - b.month_success,
      render: (text, record, index) => (
        <span className="income-font">{text}</span>
     )
    },
    {
      title: '上月投稿量',
      dataIndex: 'last_month_total',
      align: 'center',
      sorter: (a, b) => a.last_month_total - b.last_month_total,
      render: (text, record, index) => (
        <span className="income-font">{text}</span>
     )
    },{
      title: '上月通过量',
      dataIndex: 'last_month_success',
      align: 'center',
      sorter: (a, b) => a.last_month_success - b.last_month_success,
      render: (text, record, index) => (
        <span className="income-font">{text}</span>
     )
    },{
      title: '总通过量',
      dataIndex: 'total',
      align: 'center',
      sorter: (a, b) => a.total - b.total,
      render: (text, record, index) => (
        <span className="income-font">{text}</span>
     )
    }
    ];

    return (
      <Card  className="window-view ">
        <div className="addform-view" >
        <div className="custom-card-view">
            <div className="custom-card-header-view task-header-view">
              运营汇总
            </div>
            <div className="cs-header-view">
            <div className="cs-header-row">
                    <text className="cs-font2 income-font">{that.state.content.device_total}</text>
                  <text className="cs-font1">总设备量（个）</text>
                </div>
                <div className="cs-header-row">
                    <text className="cs-font2 income-font">{that.state.content.device_running_total}</text>
                  <text className="cs-font1">运营设备量（个）</text>
                </div>
                <div className="cs-header-row">
                  <text className="cs-font2 income-font">{that.state.content.scene_total}</text>
                  <text className="cs-font1">总场所数量（个）</text>
                </div>
                
            </div>
            
            <div className="cs-header-view">
                <div className="cs-header-row">
                    <text className="cs-font2 income-font">{that.state.content.user_total}</text>
                    <text className="cs-font1">累计用户数（人）</text>
                </div>
                <div className="cs-header-row">
                    <text className="cs-font2 income-font">{that.state.content.order_total}</text>
                  <text className="cs-font1">订单总笔数（笔）</text>
                  
                </div>
                <div className="cs-header-row">
                  <text className="cs-font2 income-font">{that.state.content.order_amount}</text>
                  <text className="cs-font1">订单总金额（元）</text>
                </div>
                {/* <div className="cs-header-row">
                 
                </div> */}
            </div>
    
        </div>
        {/* <div className="custom-card-view  none-bottom">
            <div className="custom-card-header-view  task-header-view">
            订单分析
            </div>
            <div id="income-play" className="g2-view"></div>
        </div> */}
        </div>
      </Card>
    );
  }
}

Statistic_gather = Form.create()(Statistic_gather);



const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Statistic_gather)



