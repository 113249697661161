import React from "react";
import './index.less'
import { connect } from "react-redux"
import { login } from '../../redux/action'
import cookie from 'react-cookies'
import * as fn from '../../utils/index'
import { Form, Input, Button, message, Icon } from "antd";
import { NavLink } from 'react-router-dom'

const FormItem = Form.Item;

class FormLogin extends React.Component {

    handleSubmit = () => {
        let userInfo = this.props.form.getFieldsValue();

        this.props.form.validateFields((err, values) => {
            if (!err) {
        
                fn.ajax({
                    url: '/auth/login.php',
                    data: {
                        mobile: userInfo.nickname,
                        pswd: userInfo.password
                    }
                }).
                    then((res) => {
                        
                        message.success(`登录成功`);
                        let userinfo = res.data;
                        console.log(userinfo)
                        cookie.save("userinfo", userinfo);
                        const { dispatch } = this.props;
                        dispatch(login(userinfo));
                    })
            }
        })
    }
    componentWillMount() {
        console.log("获取chrome对象")

    }

    render() {

        const { getFieldDecorator } = this.props.form;
        return (

            <div className="form-login-div" >
                <img src="/assets/main_footer.png" className="main-footer-pic-view"/>
                <img src="/assets/main_top.png" className="main-top-pic-view"/>
                <Form className="form-login">
                    <div className="login-bg-style"></div>
                    <FormItem>
                        <h3 className="font1  ">德宇科技</h3>
                    </FormItem>
                    <FormItem>
                        {
                            getFieldDecorator('nickname', {

                                rules: [
                                    {
                                        required: true,
                                        message: '请输入用户名'
                                    },

                                    {
                                        pattern: new RegExp('^\\w+$', 'g'),
                                        message: '用户名必须为字母或者数字'
                                    }
                                ]
                            })(
                                
                                <Input autocomplete="new-password" prefix={<Icon type="user" />} placeholder="请输入用户名" className="input-style" />
                            )
                        }
                    </FormItem>
                    <FormItem>
                        {
                            getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入密码'
                                    },
                                    {
                                        pattern: new RegExp('^\\w+$', 'g'),
                                        message: '用户名必须为字母或者数字'
                                    }
                                ]
                            })(
                                <div className="loginform-input-row-view">
                                    <Input autocomplete="new-password" prefix={<Icon type="lock" />} type="password" placeholder="请输入密码" className="input-style" onPressEnter={this.handleSubmit} />
                                    {/* <NavLink to="/reset_pswd" className="goto-password-hint-view">忘记密码</NavLink> */}
                                </div>
                             )
                        }
                    </FormItem>

                    <FormItem >
                        <div className="loginform-button-view">
                            <div className="loginform-bg-view"></div>
                            <div  onClick={this.handleSubmit} className="loginform-button-style">登录</div>
                            {/* <NavLink to="/register"><Button type="primary" className="loginform-button-style">注册</Button></NavLink> */}
                        </div>

                        {/* <a href="#">暂时没有账号，点击去注册</a> */}
                    </FormItem>
                    
                </Form>
                <a href="http://www.beian.miit.gov.cn/" target="_blank" className="footer-bar-style">©2021 深圳市德宇科技有限公司</a>
            </div>
        );
    }

}
FormLogin = connect()(FormLogin)
export default Form.create({})(FormLogin)