import React from 'react'
import './index.less'
import * as fn from '../../../utils/index'
import { Table, Divider, Button, Select, message, Popconfirm, Form, Upload, Input, InputNumber, Modal, Icon, Radio } from 'antd';
import { connect } from "react-redux"
import { NavLink } from 'react-router-dom'
import * as ACTION from '../../../redux/action/'
import '../../../style/antd_table.less'
import * as plugin from '../../../utils/plugin'
import { SOCKET_URL } from '../../../config/config'
import Websocket from 'react-websocket';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;
const FormItem = Form.Item;
const { Option } = Select;
const { TextArea } = Input;
class Order_refund_verify extends React.Component {
  state = {

    refund_amount:undefined,
    current_record:undefined,
    refund_modal_visible:false,

    current_page:1,
    order_list: [],
    total_count:undefined,
    admin_id:undefined,
    admin_data:[],
    modal_visible:false,
    account_name:''
  }
  // 初始化组件
  componentWillMount() {
    this.init_data();

  }
  // 搜索
  search = (mode=0) => {
    let that=this;
    let data = this.props.form.getFieldsValue();
    if(mode==1){
      this.init_data(data.out_trade_no,data.device,data.scene,that.state.current_page);
    }else{
      this.init_data(data.out_trade_no,data.device,data.scene,1);
    }
    
  }
 // 监听页脚切换
 change_page = page => {
    let that=this;
    this.setState({
      current_page: page,
    });
    let data = this.props.form.getFieldsValue();
    that.init_data(data.out_trade_no,"","",page);
  }

  // 初始化数据
  init_data(out_trade_no="",device_name="",scene_name="",page=1) {
    let that = this;
    let data = {};
    data.token = that.props.userinfo.token;
    data.out_trade_no=out_trade_no;
    data.device_name=device_name;
    data.scene_name=scene_name;
    data.p=page

    fn.ajax({
      url: '/order/refund_list.php',
      data: data
    }).then((res) => {
        this.setState({
          total_count: res.data.count,
          order_list:res.data.list
        })
      })
  }

  
  // 监听退款金额
  change_refund_amount(value){
    let that=this
    // console.log(value)
    that.setState({
      refund_amount:value
    })

  }
  // 隐藏退款窗口
  refund_modal_hidden(){
    let that=this
    that.setState({
      current_record:undefined,
      refund_amount:undefined,
      refund_modal_visible:false
    })
    
  }
  // 显示退款窗口
  refund_modal_show(record){
    let that=this
    that.setState({
      current_record:record,
      refund_amount:undefined,
      refund_modal_visible:true
    })
  }
  // 申请退款
  refund(record){
    let that=this
    let refund_amount=record.pay_amount
    if(!refund_amount){
      message.error("退款金额不能为空！")
      return false;
    }
    confirm({
      title: '该操作不可逆，且会影响资金变化！确认继续?',
      icon: <ExclamationCircleOutlined />,
      okText:'确认',
      cancelText:'取消',

      content: '点击确认后将进行资金退款操作！',
      onOk() {
        let data = {};
        data.token = that.props.userinfo.token;
        data.apply_id=record.refund_id;

        fn.ajax({
          url: '/order/apply_success.php',
          data: data
        }).then((res) => {
            message.success("操作成功！")
            that.search(1)
            that.refund_modal_hidden()
          })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
   
  }
  // 申请退款
  verify_reject(record){
    let that=this
    let data = {};
    data.token = that.props.userinfo.token;
    data.content_id=record.refund_id;

    fn.ajax({
      url: '/order/apply_reject.php',
      data: data
    }).
      then((res) => {
        message.success("操作成功！")
        that.search(1)
      })
  }
  

  render() {
    let that = this;
    const { getFieldDecorator } = this.props.form;
    const columns = [{
      title: '申请编号',
      dataIndex: 'refund_id',
      align: 'center',
    },{
      title: '联系手机号',
      dataIndex: 'refund_mobile',
      align: 'center',
    },{
      title: '退款原因',
      dataIndex: 'refund_cause',
      align: 'center',
      render: (text, record, index) => {
         return <div>
           <div>{text}</div>
           <div>{record.refund_desc}</div>
         </div>
      }
    },{
      title: '场所信息',
      dataIndex: 'scene_name',
      align: 'center',
      render: (text, record, index) => {
         return <div>
           <text>{record.scene_name} （{record.room}）</text>
         </div>
      }
    },{
      title: '设备号',
      dataIndex: 'device_name',
      align: 'center'
    }, {
      title: '商品信息',
      dataIndex: 'good_pic',
      align: 'center',
      render: (text, record, index) => {
         return <div className="refund-good-view">
            <img src={text} className="refund-good-pic-view"/>
            <div className="refund-good-right-view">
              <text>{record.out_trade_no}</text>
              <text>{record.good_title}</text>
              <text className="income-font">{record.pay_amount}</text>
              <text className="refund-font1">{record.create_time}</text>
            </div>
           </div>
      }
    }, 
    {
      title: '状态',
      dataIndex: 'refund_status',
      align: 'center',
      render: (text, record, index) => {
        if(text==0){
          return (<text>待审核</text>)
        }else if(text==1){
          return (<text className="income-font">通过</text>)
        }else if(text==-1){
          return (<text className="primary-font2">拒绝</text>)
        }
      }
    }, 
  {
      title: '退款申请时间',
      dataIndex: 'refund_time',
      align: 'center',
    }, {
      title: '操作',
      key: 'action',
      align: 'center',
      render: (text, record, index) => {
        let that=this
        return <div>
        {record.refund_status==0?<Popconfirm title="确认通过该申请？" okText="是" cancelText="否" onConfirm={that.refund.bind(that,record)}><Button type="primary" style={{ marginRight:"30px"}} >通过</Button></Popconfirm> :''}

        {record.refund_status==0?<Popconfirm title="确认拒绝该申请？" okText="是" cancelText="否" onConfirm={this.verify_reject.bind(this,record,2)}><Button type="primary" >拒绝</Button></Popconfirm>:''}
    </div>   
      }
    }
    ];
    
    return (
      <div >
        

        <Form layout="inline" className="main-headerform-view">
        
          <FormItem label="订单号">
            {
              getFieldDecorator('out_trade_no', {

              })(
                <Input placeholder="请输入订单号" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="场所">
            {
              getFieldDecorator('scene', {

              })(
                <Input placeholder="请输入场所" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
          <FormItem label="设备">
            {
              getFieldDecorator('device', {

              })(
                <Input placeholder="请输入设备" className="search-input-style" onPressEnter={this.search} />
              )
            }
          </FormItem>
       
        
          <FormItem>
            <Button type="primary" onClick={this.search} className="submit-search-style">搜索</Button>
          </FormItem>
        </Form>
        <Table columns={columns} style={{"marginTop":"20px"}} dataSource={this.state.order_list} className="table-view"  pagination={{
          showQuickJumper:true,
          onChange: this.change_page,
          current: this.state.current_page,
          pageSize:5,
          total: this.state.total_count

        }}/>




      </div>

    );
  }
}

Order_refund_verify = Form.create()(Order_refund_verify);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Order_refund_verify)