import React from 'react'
import './index.less'
import { Icon, Button, Card, Form, Input, InputNumber, message, Modal } from 'antd';
import { Redirect, NavLink } from 'react-router-dom'
import * as fn from '../../utils/index'
import { connect } from "react-redux"

class Reset_pswd extends React.Component {
  state = {
    data: [],
    finish_gate:false,
    goto_custom_list: false
  }

  componentWillMount() {

  }

  // 保存设置
  update_pswd() {
    let that = this;
    let data = this.props.form.getFieldsValue();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(data.new_pswd!=data.confirm_new_pswd){
          message.info("原密码与确认密码不相等！");
          return false;
        }

        data.token = that.props.userinfo.token;

        fn.ajax({
          url: '/auth/reset_pswd.php',
          data: data
        }).
          then((res) => {
            message.success("修改密码成功!");
            this.props.form.setFieldsValue({
              old_pswd:'',
              new_pswd:'',
              confirm_new_pswd:''
            })
            fn.page_back();
          })
      }
    });
  }
  // 渲染界面
  render() {
    let that = this;
    let data = that.state.data;
    const { getFieldDecorator } = this.props.form;

   

    return (
      <Card className="window-view" title={<div className="window-header-view" >
        {/* <NavLink to="/custom/list"><Button type="primary" className="window-close-view"><Icon type='arrow-left' />返回</Button></NavLink> */}
        <div className="window-title-view2">修改密码</div>
      </div>}>
        <Form className="addform-view" >
          <div className="form-top-view">
            <Form.Item
              label={<text className="form-label-view">旧密码</text>} className="addform-item-view5"
            >
              {getFieldDecorator('old_pswd', {
                rules: [{
                  required: true, message: '请输入旧密码',
                },{
                  min:6,message:'密码为6到16位字母数字组合'
                },{
                  max:16,message:'密码为6到16位字母数字组合'
                }],
              })(
                <Input autocomplete="new-password" placeholder="请输入旧密码" style={{ width: 300 }} />
              )}
            </Form.Item>

            <Form.Item
              label={<text className="form-label-view">新密码</text>} className="addform-item-view5"
            >
              {getFieldDecorator('new_pswd', {
                rules: [{
                  required: true, message: '请输入新密码',
                },{
                  min:6,message:'密码为6到16位字母数字组合'
                },{
                  max:16,message:'密码为6到16位字母数字组合'
                }],
              })(
                <Input autocomplete="new-password" type="password" placeholder="请输入新密码" style={{ width: 300 }} />
              )}
            </Form.Item>

            <Form.Item
              label={<text className="form-label-view">确认密码</text>} className="addform-item-view5"
            >
              {getFieldDecorator('confirm_new_pswd', {
                rules: [{
                  required: true, message: '请输入确认密码',
                },{
                  min:6,message:'密码为6到16位字母数字组合'
                },{
                  max:16,message:'密码为6到16位字母数字组合'
                }],
              })(
                <Input autocomplete="new-password" type="password" onPressEnter={this.update_pswd.bind(this)} placeholder="请输入确认密码" style={{ width: 300 }} />
              )}
            </Form.Item>

          </div>
          <Form.Item className="form-down-view">
            <Button type="primary" onClick={this.update_pswd.bind(this)}>保存</Button>
          </Form.Item>
        </Form>

      </Card>
    );
  }
}

Reset_pswd = Form.create()(Reset_pswd);
const mapStateToProps = state => {
  return {
    userinfo: state.userinfo
  }
};
export default connect(mapStateToProps)(Reset_pswd)