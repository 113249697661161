import React from 'react'
import { connect } from "react-redux"
import Admin from './admin'
import Home from './pages/home';
import FormLogin from './pages/login';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Reset_pswd from './pages/reset_pswd'
import Promise from 'promise-polyfill';
import * as fn from './utils/index'
import { login } from './redux/action'
import cookie from 'react-cookies'
import Role_list from './pages/privilege/role_list'
import Admin_list from './pages/privilege/admin_list'
import Part_list from './pages/privilege/part_list'
import Admin_detail from './pages/privilege/admin_detail'
import Order_list from './pages/order/list'
import Good_list from './pages/good/list'
import Device_list from './pages/device/list'
import Scene_list from './pages/scene/list'
import Statistic_gather from './pages/statistic/gather'
import Shop_list from './pages/shop/list'
import Good_add from './pages/good/add'
import User_list from './pages/user/list';
import Good_edit from './pages/good/edit';
import Scene_add from './pages/scene/add';
import Scene_edit from './pages/scene/edit'
import Device_type from './pages/device/type_list'
import Device_plan from './pages/device/plan_list'
import Withdraw_list from './pages/withdraw/list'
import Order_refund_verify from './pages/order/refund_verify'

// To add to window  解决promise 在ie中未定义的问题
if (!window.Promise) {
 window.Promise = Promise;
 console.log(window.Promise)
}
/*兼容处理 低版本IE*/
//
Array.prototype.find || (Array.prototype.find = function (predicate) { 
    if (this == null) {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function') {
      throw new TypeError('predicate must be a function');
    }
    var list = Object(this);
    var length = list.length || 0;
    var thisArg = arguments[1];
    var value;

    for (var i = 0; i < length; i++) {
      value = list[i];
      if (predicate.call(thisArg, value, i, list)) {
        return value;
      }
    }
    return null;
})

// IE 兼容方法
if (typeof Object.assign != 'function') {
    Object.assign = function(target) {
      'use strict';
      if (target == null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }
  
      target = Object(target);
      for (var index = 1; index < arguments.length; index++) {
        var source = arguments[index];
        if (source != null) {
          for (var key in source) {
            if (Object.prototype.hasOwnProperty.call(source, key)) {
              target[key] = source[key];
            }
          }
        }
      }
      return target;
    };
  }


if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback, type, quality) {
      var canvas = this;
      setTimeout(function() {
        var binStr = atob( canvas.toDataURL(type, quality).split(',')[1] );
        var len = binStr.length;
        var arr = new Uint8Array(len);
 
        for (var i = 0; i < len; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
 
        callback(new Blob([arr], { type: type || 'image/png' }));
      });
    }
  });
}

class IRouter extends React.Component {

    componentWillMount() {
        let userinfo=this.props.userinfo
    }

    render() {
      let that=this;

        if (this.props.userinfo == null) {
            return (
                <HashRouter>
                    <Switch>
                        <Route path='/login' component={FormLogin} />
                 
                        <Redirect to="/login" />
                    </Switch>
                </HashRouter>
            );
        }
        let router_path='/home'
        if(that.props.userinfo.role_id==9){
          router_path='/statistic/gather'
        }
       
        return (
            <HashRouter>
                <Switch>
                    <Route path="/" render={() =>
                        <Admin>
                            <Route path='/home' component={Home} />
                            <Route path='/reset_pswd' component={Reset_pswd} />
                            <Route path='/role/role_list' component={Role_list} />
                            <Route path='/privilege/admin_list' component={Admin_list} />
                            <Route path='/privilege/admin_detail' component={Admin_detail} />
                            <Route path="/privilege/part_list" component={Part_list}/>
                            <Route path='/order/list' component={Order_list} />
                            <Route path='/good/list' component={Good_list} />
                            <Route path='/device/list' component={Device_list} />
                            <Route path='/scene/list' component={Scene_list} />
                            <Route path='/statistic/gather' component={Statistic_gather} />
                            <Route path='/shop/list' component={Shop_list} />
                            <Route path='/good/add' component={Good_add} />
                            <Route path='/user/list' component={User_list} />
                            <Route path="/good/edit" component={Good_edit}/>
                            <Route path="/scene/add" component={Scene_add}/>
                            <Route path="/scene/edit" component={Scene_edit}/>
                            <Route path="/device/type_list" component={Device_type}/>
                            <Route path="/device/plan_list" component={Device_plan}/>
                            <Route path="/withdraw/list" component={Withdraw_list}/>
                            <Route path="/order/refund_verify" component={Order_refund_verify}/>
                         
                            <Redirect to={router_path} />
                        </Admin>
                    } />
                </Switch>

            </HashRouter>
        );
    }
}
const mapStateToProps = state => {

    return {
        userinfo: state.userinfo
    }
};
export default connect(mapStateToProps)(IRouter)



